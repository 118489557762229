import type { BaseSyntheticEvent, FC } from 'react'
import { useCallback } from 'react'

import Autocomplete from '@web-solutions/core/containers/input-autocomplete';

import { useEmailAutocompleteProcessor } from '@web-solutions/core/hooks/use-email-autocomplete-processor.ts'

import { InputWrapper, type InputWrapperProps } from '../input-wrapper'

import classes from './style.module.scss'

interface EmailInputProps extends InputWrapperProps { }

export const EmailInput: FC<EmailInputProps> = ({ name, value, onChange, onBlur, ...props }) => {
  const { isVisible, autocompleteEmailDomains, setIsVisible, handleAutocompleteChange } = useEmailAutocompleteProcessor();

  const valueWithoutEmailDomain = value?.split('@')[0];
  const options = autocompleteEmailDomains.map(domain => ({ label: valueWithoutEmailDomain + domain }));

  const handleInputBlur = useCallback((e: BaseSyntheticEvent) => {
    onBlur && onBlur(e);
    setTimeout(() => setIsVisible(false), 200)
  }, [setIsVisible, onBlur])

  const handleInputChange = useCallback((e: BaseSyntheticEvent) => {
    onChange && onChange(e);
    handleAutocompleteChange(e.target.value)
  }, [handleAutocompleteChange, onChange])

  const handleAutocompleteSelect = useCallback(({ label }: Record<'label', string>) => {
    //@ts-ignore
    onChange && onChange({ target: { name, value: label } })
  }, [name, onChange])

  return (
    <>
      <InputWrapper
        name={name}
        value={value}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
        {...props}
      />
      {isVisible && (
        <Autocomplete
          isEmail
          className={classes.autocompleteWrapper}
          options={options}
          onSelect={handleAutocompleteSelect}
        />
      )}
    </>
  )
}
