export enum PLANET_IDS {
  ASCENDANT = 'ascendant',
  JUPITER = 'jupiter',
  MARS = 'mars',
  MERCURY = 'mercury',
  MOON = 'moon',
  NEPTUNE = 'neptune',
  PLUTO = 'pluto',
  SATURN = 'saturn',
  SUN = 'sun',
  URANUS = 'uranus',
  VENUS = 'venus',
}

export const PLANETS = [PLANET_IDS.ASCENDANT, PLANET_IDS.SUN, PLANET_IDS.MOON]