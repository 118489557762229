import AQUARIUS_ICON_MATTE from './icons/signs-icons/matte/aquarius.webp';
import AQUARIUS_ICON_MATTE2X from './icons/signs-icons/matte/aquarius@2x.webp';
import AQUARIUS_ICON_MATTE3X from './icons/signs-icons/matte/aquarius@3x.webp';

import ARIES_ICON_MATTE from './icons/signs-icons/matte/aries.webp';
import ARIES_ICON_MATTE2X from './icons/signs-icons/matte/aries@2x.webp';
import ARIES_ICON_MATTE3X from './icons/signs-icons/matte/aries@3x.webp';

import CANCER_ICON_MATTE from './icons/signs-icons/matte/cancer.webp';
import CANCER_ICON_MATTE2X from './icons/signs-icons/matte/cancer@2x.webp';
import CANCER_ICON_MATTE3X from './icons/signs-icons/matte/cancer@3x.webp';

import CAPRICORN_ICON_MATTE from './icons/signs-icons/matte/capricorn.webp';
import CAPRICORN_ICON_MATTE2X from './icons/signs-icons/matte/capricorn@2x.webp';
import CAPRICORN_ICON_MATTE3X from './icons/signs-icons/matte/capricorn@3x.webp';

import GEMINI_ICON_MATTE from './icons/signs-icons/matte/gemini.webp';
import GEMINI_ICON_MATTE2X from './icons/signs-icons/matte/gemini@2x.webp';
import GEMINI_ICON_MATTE3X from './icons/signs-icons/matte/gemini@3x.webp';

import LEO_ICON_MATTE from './icons/signs-icons/matte/leo.webp';
import LEO_ICON_MATTE2X from './icons/signs-icons/matte/leo@2x.webp';
import LEO_ICON_MATTE3X from './icons/signs-icons/matte/leo@3x.webp';

import LIBRA_ICON_MATTE from './icons/signs-icons/matte/libra.webp';
import LIBRA_ICON_MATTE2X from './icons/signs-icons/matte/libra@2x.webp';
import LIBRA_ICON_MATTE3X from './icons/signs-icons/matte/libra@3x.webp';

import PISCES_ICON_MATTE from './icons/signs-icons/matte/pisces.webp';
import PISCES_ICON_MATTE2X from './icons/signs-icons/matte/pisces@2x.webp';
import PISCES_ICON_MATTE3X from './icons/signs-icons/matte/pisces@3x.webp';

import SAGITTARIUS_ICON_MATTE from './icons/signs-icons/matte/sagittarius.webp';
import SAGITTARIUS_ICON_MATTE2X from './icons/signs-icons/matte/sagittarius@2x.webp';
import SAGITTARIUS_ICON_MATTE3X from './icons/signs-icons/matte/sagittarius@3x.webp';

import SCORPION_ICON_MATTE from './icons/signs-icons/matte/scorpio.webp';
import SCORPION_ICON_MATTE2X from './icons/signs-icons/matte/scorpio@2x.webp';
import SCORPION_ICON_MATTE3X from './icons/signs-icons/matte/scorpio@3x.webp';

import TAURUS_ICON_MATTE from './icons/signs-icons/matte/taurus.webp';
import TAURUS_ICON_MATTE2X from './icons/signs-icons/matte/taurus@2x.webp';
import TAURUS_ICON_MATTE3X from './icons/signs-icons/matte/taurus@3x.webp';

import VIRGO_ICON_MATTE from './icons/signs-icons/matte/virgo.webp';
import VIRGO_ICON_MATTE2X from './icons/signs-icons/matte/virgo@2x.webp';
import VIRGO_ICON_MATTE3X from './icons/signs-icons/matte/virgo@3x.webp';

export const ARIES = 'aries';
export const TAURUS = 'taurus';
export const GEMINI = 'gemini';
export const CANCER = 'cancer';
export const LEO = 'leo';
export const VIRGO = 'virgo';
export const LIBRA = 'libra';
export const SCORPIO = 'scorpio';
export const SAGITTARIUS = 'sagittarius';
export const CAPRICORN = 'capricorn';
export const AQUARIUS = 'aquarius';
export const PISCES = 'pisces';
export const FIRE = 'fire';
export const WATER = 'water';
export const EARTH = 'earth';
export const AIR = 'air';

export const SIGNS_ICONS_MATTE = {
  [ARIES]: {
    src: ARIES_ICON_MATTE3X,
    srcSet: `${ARIES_ICON_MATTE}, ${ARIES_ICON_MATTE2X} 2x, ${ARIES_ICON_MATTE3X} 3x`,
  },
  [TAURUS]: {
    src: TAURUS_ICON_MATTE3X,
    srcSet: `${TAURUS_ICON_MATTE}, ${TAURUS_ICON_MATTE2X} 2x, ${TAURUS_ICON_MATTE3X} 3x`,
  },
  [GEMINI]: {
    src: GEMINI_ICON_MATTE3X,
    srcSet: `${GEMINI_ICON_MATTE}, ${GEMINI_ICON_MATTE2X} 2x, ${GEMINI_ICON_MATTE3X} 3x`,
  },
  [CANCER]: {
    src: CANCER_ICON_MATTE3X,
    srcSet: `${CANCER_ICON_MATTE}, ${CANCER_ICON_MATTE2X} 2x, ${CANCER_ICON_MATTE3X} 3x`,
  },
  [LEO]: {
    src: LEO_ICON_MATTE3X,
    srcSet: `${LEO_ICON_MATTE}, ${LEO_ICON_MATTE2X} 2x, ${LEO_ICON_MATTE3X} 3x`,
  },
  [VIRGO]: {
    src: VIRGO_ICON_MATTE3X,
    srcSet: `${VIRGO_ICON_MATTE}, ${VIRGO_ICON_MATTE2X} 2x, ${VIRGO_ICON_MATTE3X} 3x`,
  },
  [LIBRA]: {
    src: LIBRA_ICON_MATTE3X,
    srcSet: `${LIBRA_ICON_MATTE}, ${LIBRA_ICON_MATTE2X} 2x, ${LIBRA_ICON_MATTE3X} 3x`,
  },
  [SCORPIO]: {
    src: SCORPION_ICON_MATTE3X,
    srcSet: `${SCORPION_ICON_MATTE}, ${SCORPION_ICON_MATTE2X} 2x, ${SCORPION_ICON_MATTE3X} 3x`,
  },
  [SAGITTARIUS]: {
    src: SAGITTARIUS_ICON_MATTE3X,
    srcSet: `${SAGITTARIUS_ICON_MATTE}, ${SAGITTARIUS_ICON_MATTE2X} 2x, ${SAGITTARIUS_ICON_MATTE3X} 3x`,
  },
  [CAPRICORN]: {
    src: CAPRICORN_ICON_MATTE3X,
    srcSet: `${CAPRICORN_ICON_MATTE}, ${CAPRICORN_ICON_MATTE2X} 2x, ${CAPRICORN_ICON_MATTE3X} 3x`,
  },
  [AQUARIUS]: {
    src: AQUARIUS_ICON_MATTE3X,
    srcSet: `${AQUARIUS_ICON_MATTE}, ${AQUARIUS_ICON_MATTE2X} 2x, ${AQUARIUS_ICON_MATTE3X} 3x`,
  },
  [PISCES]: {
    src: PISCES_ICON_MATTE3X,
    srcSet: `${PISCES_ICON_MATTE}, ${PISCES_ICON_MATTE2X} 2x, ${PISCES_ICON_MATTE3X} 3x`,
  },
};