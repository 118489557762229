import type { FC, ComponentProps } from 'react'
import _map from 'lodash/map'

import { t } from '@web-solutions/module-localization'

import { useRemoteConfig } from 'src/hooks/use-remote-config'

import classes from './style.module.scss'

const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

export const Footer: FC<ComponentProps<'div'>> = () => {
  const {
    policyLinks,
  } = useRemoteConfig();

  const links = {
    terms_of_service: policyLinks.termsOfUse,
    privacy_policy: policyLinks.privacy,
    help_center: `mailto:${SUPPORT_EMAIL}?subject=${encodeURIComponent('Footer')}`,
  };

  return (
    <footer>
      <ul className={classes.footerWrapper}>
        {_map(links, (value, key) => (
          <li key={key}>
            <a href={value} className={classes.link}>
              {t(`footer.links.${key}`)}
            </a>
          </li>
        ))}
      </ul>
    </footer>
  )
}
