import { useState, useCallback } from 'react';
import { AUTOCOMPOLETE_EMAIL_DOMAINS } from '@web-solutions/core/constants/autocomplete-emails';

export const useEmailAutocompleteProcessor = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [autocompleteEmailDomains, setAutocompleteEmailDomains] = useState(AUTOCOMPOLETE_EMAIL_DOMAINS);

  const handleAutocompleteChange = useCallback((value: string) => {
    const minLength = 3;
    const valueEmailDomainIndex = value.indexOf('@');

    if (valueEmailDomainIndex >= 0) {
      const emailDomain = value.slice(valueEmailDomainIndex);
      const filteredDomains = AUTOCOMPOLETE_EMAIL_DOMAINS.filter(item =>
        item.includes(emailDomain) && !value.endsWith(item)
      );

      setIsVisible(filteredDomains.length > 0);
      setAutocompleteEmailDomains(filteredDomains);
    } else {
      setIsVisible(value.length >= minLength);
    }
  }, []);

  return {
    isVisible,
    setIsVisible,
    autocompleteEmailDomains,
    handleAutocompleteChange
  };
};
