import { useMemo, type BaseSyntheticEvent, useRef } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

import { Select } from 'core/ui-elements';

import { BirthDateType } from 'src/screens/personalization/types';

import classes from './style.module.scss';

const tKey = "personalization.form.date_birth";

interface Props {
  birthDate: BirthDateType
  onDateChange: (date: BirthDateType) => void;
  onBlurBirthDate: (e: BaseSyntheticEvent) => void;
}

export const BirthDate: React.FC<Props> = ({ onDateChange, onBlurBirthDate, birthDate }) => {
  const currentFieldRef = useRef('');
  const daysOptions = useMemo(() => {
    const daysInMonth = dayjs(`${birthDate.year}-${birthDate.month}-01`).daysInMonth();

    return Array.from({ length: daysInMonth }, (_, i) => ({ value: String(i + 1) }));
  }, [birthDate.year, birthDate.month]);

  const monthOptions = useMemo(() => {
    return Array.from({ length: 12 }, (_, i) => ({
      value: String(i + 1),
      label: dayjs().month(i).format('MMMM')
    }));
  }, []);

  const yearsOptions = useMemo(() => {
    const currentYear = dayjs().year();
    const minYear = currentYear - 120;
    const maxYear = currentYear;
    return Array.from({ length: maxYear - minYear + 1 }, (_, i) => ({ value: String(maxYear - i) }));
  }, []);

  const handleChange = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    const data: BirthDateType = {
      day: '',
      month: '',
      year: '',
    };
    const formData = new FormData(e.currentTarget as HTMLFormElement);
    formData.forEach((value, key) => {
      if (key in data) {
        data[key as keyof BirthDateType] = value as string;
      }
    });

    onDateChange(data);
  };

  const handleFocus = (e: BaseSyntheticEvent) => {
    const { name } = e.target;
    currentFieldRef.current = name;
  }

  const handleBlur = (e: BaseSyntheticEvent) => {
    currentFieldRef.current = '';

    setTimeout(() => {
      if (!currentFieldRef.current) {
        onBlurBirthDate(e);
      }
    }, 300);
  }

  return (
    <div className={classes.birth_date}>
      <form className={classes.birth_date_selects} onChange={handleChange} onBlur={handleBlur} onFocus={handleFocus}>
        <Select
          defaultValue={birthDate.month}
          name="month"
          options={monthOptions}
          classNameWrap={classNames(classes.select_wrap, { [classes.disabled]: !birthDate.month })}
          disabledSelected={t(`${tKey}.month_placeholder`)}
        />
        <Select
          defaultValue={birthDate.day}
          name="day"
          options={daysOptions}
          classNameWrap={classNames(classes.select_wrap, { [classes.disabled]: !birthDate.day })}
          disabledSelected={t(`${tKey}.day_placeholder`)}
        />
        <Select
          defaultValue={birthDate.year}
          name="year"
          options={yearsOptions}
          classNameWrap={classNames(classes.select_wrap, { [classes.disabled]: !birthDate.year })}
          disabledSelected={t(`${tKey}.year_placeholder`)}
        />
      </form>
    </div>
  )
}