import { type FC, StrictMode, Suspense } from 'react';
import queryString from 'query-string';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter, Navigate, Route } from 'react-router-dom';

import Attribution, { LinkTarget } from '@web-solutions/module-attribution';
import Analytics from '@web-solutions/module-analytics';

import { parseUrlParams } from '@web-solutions/core/utils/url-sync';
import { Loader } from '@web-solutions/core/components/loader';

import { cacheImagesCascade } from 'core/utils/images';

import { load } from 'src/store/app/actions';
import { getMagnusToken } from 'src/store/app/utils';

import { APP_LINKS } from 'src/constants/general';
import { PROTECTED_ROUTES, PUBLIC_ROUTES, ROUTES } from 'src/constants/routes';
import { SCREENS } from 'src/constants/screens';

import packageInfo from '../package.json';

import configureStore from './store';
import { initLocalization } from './localization';

import { IMAGES_WELCOME } from './screens/book-landing/sections/welcome/images';

import { ProtectedRoute } from './hocs/protected-route';
import { PageTransition } from './hocs/page-transition';

import 'src/styles/global.scss';
import 'src/styles/index.scss';
import 'src/styles/variables.css';

const { store, persistor } = configureStore();

const p = queryString.parse(window.location.search);

// TODO
cacheImagesCascade([IMAGES_WELCOME.BG])

const App: FC = () => {
  return (
    <StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<Loader />}>
            <BrowserRouter>
              <PageTransition>
                <Route element={<ProtectedRoute />}>
                  {Object.values(PROTECTED_ROUTES).map((r, i) => (
                    <Route key={i} path={r} element={SCREENS[r]} />
                  ))}
                </Route>
                {Object.values(PUBLIC_ROUTES).map((r, i) => (
                  <Route key={i} path={r} element={SCREENS[r]} />
                ))}
                <Route path='*' element={
                  <Navigate replace to={{
                    pathname: ROUTES.MAIN,
                    search: window.location.search,
                  }} />}
                />
              </PageTransition>
            </BrowserRouter>
          </Suspense>
        </PersistGate>
      </Provider>
    </StrictMode>
  );
};

const init = async () => {
  if (p.reset !== undefined) {
    setInterval(() => {
      window.localStorage.clear();
    }, 10);
    setTimeout(() => {
      delete p.reset;
    }, 1000);
  }
  initLocalization();
  Analytics.init(getMagnusToken(), packageInfo.version);

  Attribution.init(APP_LINKS);
  Attribution.setRemoteConfigTarget(LinkTarget.WEB);

  return store.dispatch(load());
}

export const render = () => {
  parseUrlParams();
  const container = document.getElementById('root');
  const root = createRoot(container!);
  init().then(() => {
    root.render(<App />);
  })
};

render();
