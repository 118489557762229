import type { FC, ComponentProps } from 'react'
import classNames from 'classnames'

import classes from './style.module.scss'

interface CrossButtonProps extends ComponentProps<'button'> { }

export const CrossButton: FC<CrossButtonProps> = ({ className, children, ...props }) => (
  <button
    className={classNames(classes.crossButton, className)}
    {...props}
  >
    {children}
  </button>
);