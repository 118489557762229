import type { FC } from 'react';

import Analytics from '@web-solutions/module-analytics';
import { t } from '@web-solutions/module-localization';

import { Modal } from '@web-solutions/core/ui-elements';

import { EVENT_ACTION } from 'src/constants/events';

import { CrossButton } from 'src/components/cross-button'

import { PersonalizedForm } from './components';

import classes from './style.module.scss';

interface Props {
  visible: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const PersonalizationPopUp: FC<Props> = ({ visible, onClose, onSubmit }) => {
  const handleClose = () => {
    Analytics.trackEvent('personalized_form', EVENT_ACTION.CLOSE);

    onClose();
  }

  return (
    <Modal
      isOpen={visible}
      className={classes.personalization_popup}
      overlayClassName={classes.overlay}
    >
      <CrossButton className={classes.closeButton} onClick={handleClose} />
      <PersonalizedForm onSubmit={onSubmit} />
      <div className={classes.footnote}>{t('personalization.footnote')}</div>
    </Modal >
  );
};