import { type FC, useCallback, useEffect, useState } from 'react';
import _map from 'lodash/map';
import _uniqBy from 'lodash/uniqBy';
import _pick from 'lodash/pick';

import { searchAddress } from '@web-solutions/core/api/nominatim';

import { useGetDebouncedValue } from '@web-solutions/core/hooks/use-get-debounced-value';

import Autocomplete from '../../../input-autocomplete';

import type { PlaceData } from '../../types';

import { getSearchQuery } from './utils';

interface PlaceAutocompleteProps {
  value: Record<'label', string>,
  onSelect: (value: PlaceData) => void
  searchValue?: string,
  show?: boolean,
  formatter?: (value: PlaceData['address']) => string
  filter?: (value: PlaceData) => boolean
}

export const PlaceAutocomplete: FC<PlaceAutocompleteProps> = ({
  show,
  value,
  searchValue,
  formatter,
  filter,
  onSelect,
}) => {
  const [options, setOptions] = useState<PlaceData[] | null>(null);
  const [selected, setSelected] = useState<string | boolean>(value?.label);
  const debouncedValue = useGetDebouncedValue(value?.label)

  useEffect(() => {
    setOptions(null);

    if (value?.label !== selected) {
      setSelected(false);
    }

    if (debouncedValue && debouncedValue !== selected && show) {
      const searchQuery = searchValue || getSearchQuery(debouncedValue);

      searchAddress(searchQuery, formatter)
        .then(r => {
          setOptions(_map(_uniqBy(r, 'label'), i => _pick(i, 'label', 'lat', 'lon', 'address')).filter(i => i?.label && (filter ? filter(i) : true)));
        })
        .catch((err) => {
          if (err?.name === 'AbortError') {
            console.warn(err);
          } else {
            throw err;
          }
        });
    }

  }, [debouncedValue, selected, searchValue, show]);

  const handleSelect = useCallback((v: PlaceData) => {
    setSelected(v?.label);

    onSelect(v);
  }, [setSelected, onSelect]);

  return options && !selected && show
    ? (
      <Autocomplete
        options={options}
        onSelect={handleSelect}
      />
    )
    : null;
};

export default PlaceAutocomplete;
