import React from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';

interface Props {
  value: number;
  ringColor: string;
  trailColor: string;
  children: React.ReactNode;
}

const CircleProgress: React.FC<Props> = (
  {
    value,
    ringColor,
    trailColor,
    children,
  }
) => {
  return (
    <CircularProgressbarWithChildren
      value={value}
      strokeWidth={8}
      styles={buildStyles({
        strokeLinecap: 'round',
        pathColor: ringColor,
        textColor: ringColor,
        trailColor: trailColor,
      })}
    >
      {children}
    </CircularProgressbarWithChildren>
  );
};

export default CircleProgress;
