import _map from 'lodash/map';

import { useState } from 'react';

import { type Swiper as SwiperType } from 'swiper';
import { Thumbs } from 'swiper/modules';

import { Swiper } from '@web-solutions/core/ui-elements';

import { t, T } from '@web-solutions/module-localization';

import { IMAGES } from './images';

import classes from './style.module.scss';

const tKey = 'landing.planets_affect';

type Planets = {
  mercury: { title: string, text: string },
  venus: { title: string, text: string },
  mars: { title: string, text: string },
  jupiter: { title: string, text: string },
  uranus: { title: string, text: string },
  neptune: { title: string, text: string },
  pluto: { title: string, text: string },
  saturn: { title: string, text: string },
}

const PlanetsAffect = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null);  
  const planets = t(`${tKey}.planets`, { returnObjects: true }) as any as Planets;

  const tabs = _map(planets, (v, k) => (
    <div
      key={k}
      className={classes.tab}
    >
      <p className={classes.tab_text}><T k={v.title} /></p>
    </div>
  ));

  const planetContent = _map(planets, (v, k) => (
    <div
      key={k}
      className={classes.planet}
    >
      <p className={classes.planet_title}>{v.title}</p>
      <p className={classes.text}>{v.text}</p>
      <img src={IMAGES[k].src} srcSet={IMAGES[k].srcSet} alt="planet" className={classes.planet_img} />
    </div>
  ));

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <p className={classes.title}><T k={t(`${tKey}.title`)} /></p>
        <p className={classes.subtitle}>{t(`${tKey}.subtitle`)}</p>
      </div>
      <div className={classes.planet_content}>
        <Swiper
          centeredSlides={true}
          pagination={false}
          slidesPerView={1}
          spaceBetween={0}
          slides={planetContent}
          loop
          className={classes.mainSlider}
          modules={[Thumbs]}
          thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
          watchSlidesProgress={true}
        />
        <Swiper
          pagination={false}
          centeredSlides={false}
          slidesPerView={4.3}
          spaceBetween={8}
          className={classes.tabs}
          slides={tabs}
          onSwiper={setThumbsSwiper}
          loop
        />
      </div>
      <footer />
    </div>
  );
}

export default PlanetsAffect;