import type { FC } from 'react'
import { useSelector } from 'react-redux'

import { t } from '@web-solutions/module-localization'

import { Text, Title } from '@web-solutions/core/ui-elements'

import { getShippingInformation } from 'src/store/profile/selectors'

import { ProductAndCustomerInfo } from 'src/components/product-and-customer-info'
import { Footer } from 'src/components/order/footer'

import { baseTKey } from './constants'

import { SuccessIcon } from './icons'

import { NavHeader, OrderStatus, OrderDetails } from './components'

import classes from './style.module.scss'

export const OrderSuccess: FC = () => {
  const { email } = useSelector(getShippingInformation);

  return (
    <div className={classes.orderSuccessWrapper}>
      <NavHeader />
      <main className={classes.mainWrapper}>
        <div className={classes.confirmationWrapper}>
          <div className={classes.titleWrapper}>
            <SuccessIcon className={classes.icon} />
            <Title level='h2' className={classes.title}>
              {t(`${baseTKey}.main.title`)}
            </Title>
          </div>
          <Text className={classes.subtitle}>
            {t(`${baseTKey}.main.subtitle`)}
          </Text>
          <span className={classes.email}>
            {email}
          </span>
        </div>
        <section className={classes.section}>
          <ProductAndCustomerInfo />
          <OrderStatus />
          <div className={classes.devider} />
          <OrderDetails />
        </section>
        <Footer />
      </main>
    </div>
  )
}