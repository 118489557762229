import { t } from '@web-solutions/module-localization';

import { ReactComponent as RatingIcon } from './rating.svg';

import classes from './style.module.scss';

const tKey = 'landing.readers_say';

const rating = '4.79';

export const Raiting = () => {
  return (
    <div className={classes.raiting_block}>
      <p className={classes.raiting_text}>{t(`${tKey}.rating`)}</p>
      <p className={classes.raiting}>{rating}</p>
      <div className={classes.reviews_block}>
        <RatingIcon />
        <p className={classes.reviews}>{t(`${tKey}.reviews_top_text`)}</p>
      </div>
    </div>
  );
}
