import { type FC } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { t } from '@web-solutions/module-localization'

import { Title } from '@web-solutions/core/ui-elements';

import { parseCurrencyString } from '@web-solutions/core/utils/parse-currency';
import { getCurrencySymbols } from '@web-solutions/react-billing/utils/prices';

import { selectCartProducts } from 'src/store/cart/selectors';

import { ROUTES } from 'src/constants/routes';

import { ProductCard } from 'src/components/product-card'
import { OrderButton } from 'src/components/order/order-button'

import { baseTKey } from './constants'

import { Header, DeleteButton, TotalPrice } from './components'

import classes from './style.module.scss'

export const Cart: FC = () => {
  const navigateTo = useNavigate()

  const cartProducts = useSelector(selectCartProducts);

  const totalPrice = cartProducts.reduce((acc, item) => acc + parseCurrencyString(item.price.toString()), 0);

  const { currencySymbol, currencySuffix } = getCurrencySymbols(cartProducts?.[0]?.currency);

  const handleProceedClick = () => {
    navigateTo({
      pathname: ROUTES.SHIPPING,
      search: window.location.search
    })
  }

  return (
    <div className={classes.cartWrapper}>
      <Header />
      {cartProducts.map((item) => (
        <ProductCard key={item.id} product={item}>
          <DeleteButton product={item} />
        </ProductCard >
      ))}
      {cartProducts.length ?
        (
          <>
            <TotalPrice totalPrice={`${currencySymbol}${totalPrice.toFixed(2)}${currencySuffix}`} />
            <OrderButton
              className={classes.button}
              title={t(`${baseTKey}.button_title`)}
              onClick={handleProceedClick}
            />
          </>
        ) :
        (
          <Title level='h2' className={classes.noProducts}>
            {t(`${baseTKey}.no_products`)}
          </Title>
        )}
    </div>
  )
}

export default Cart
