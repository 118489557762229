import type { FC, ComponentProps } from 'react'
import classNames from 'classnames'

import { ReactComponent as WarningIcon } from './warning.svg';

import classes from './style.module.scss';

interface ErrorBlockProps extends ComponentProps<'div'> {
  text: string
}

export const ErrorBlock: FC<ErrorBlockProps> = ({ className, text, ...props }) => (
  <div className={classNames(classes.error_block, className)} {...props}>
    <div className={classes.left_block}>
      <WarningIcon />
      <p className={classes.text}>
        {text}
      </p>
    </div>
  </div>
)