import { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import DeviceProps from '@magnus/react-native-device-props';

import Billing from '@web-solutions/module-billing';

import { PaymentSystem } from '@web-solutions/react-billing';

import { createPayPalOrders, createCustomer, setOrderPending } from '@web-solutions/core/store/billing/actions';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import type { OneTimePaymentProps } from '..';

type Params = Pick<OneTimePaymentProps, 'activeProduct' | 'oneTimeProducts' | 'trigger' | 'metadata' | 'solidMetadata'>

export const useCreateOrdersProcessor = ({ activeProduct, oneTimeProducts, trigger, metadata, solidMetadata }: Params) => {
  const dispatch = useDispatch<any>();

  const [orderDetails, setOrderDetails] = useState(null);

  const email = useSelector((state: any) => state.profile.email);

  const {
    modePayPal,
  } = useRemoteConfig();

  const createOrders = useCallback(async () => {
    try {
      dispatch(setOrderPending(true));

      setOrderDetails(null);

      if (!activeProduct && !oneTimeProducts) return

      if (modePayPal === 'solidgate' && email) {
        const paypalProducts = oneTimeProducts ? oneTimeProducts.filter((item) => activeProduct?.id !== item.id) : []
        const paypalProduct = activeProduct ? activeProduct : paypalProducts?.[0]
        const products = [paypalProduct, ...paypalProducts]

        await dispatch(createCustomer());

        dispatch(
          createPayPalOrders({
            one_time_products: products,
            paymentSystem: PaymentSystem.SOLIDGATE,
            trigger,
            metadata,
            solidMetadata
          })
        );
      }

      const idfm = await DeviceProps.getIDFM();

      const res = await Billing.createOrder({
        orderDescription: idfm,
        one_time_product: activeProduct || oneTimeProducts?.[0],
        one_time_products: oneTimeProducts || [],
        trigger,
        metadata,
        solidMetadata,
        paymentSystem: PaymentSystem.SOLIDGATE,
      } as any);

      setOrderDetails(res as any);
    } catch (error) {
      console.warn('[ERROR CREATE ORDER]', error);
    } finally {
      dispatch(setOrderPending(false));
    }

  }, [activeProduct, oneTimeProducts, trigger, metadata, solidMetadata, email, modePayPal, dispatch])

  return {
    orderDetails,
    createOrders
  }
}