import { useSelector } from 'react-redux';

import { t, T } from '@web-solutions/module-localization';

import { LandingButton } from 'src/components/landing-button';

import { getPersonalizedData } from 'src/store/profile/selectors';

import {ReactComponent as DoneIcon} from './done.svg';

import classes from './style.module.scss';

const tKey = 'landing.delivery_steps'

export const DeliverySteps = () => {
  const personalizedData = useSelector(getPersonalizedData);
  const isPersonalized = !!personalizedData;

  const steps = t(`${tKey}.steps`, { returnObjects: true }) as unknown as Array<Record<string, string>>;

  return <div className={classes.wrapper}>
    <div className={classes.title}>
      {t(`${tKey}.title`)}
    </div>
    <div className={classes.steps}>
      {steps.map(({ title, text }, index) => {
        return <div className={classes.step} key={index}>
          <div className={classes.step_number}>
            {(index === 0 && isPersonalized) ?
              (
                <div className={classes.done}>
                  <div className={classes.done_point}>
                    <DoneIcon />
                  </div>
                  <p className={classes.done_text}>{t(`${tKey}.done`)}</p>
                </div>
              )
              :
              (<T k={`${tKey}.step`} tOptions={{ step: index + 1 }} />)}
          </div>
          <div className={classes.step_content}>
            <div className={classes.step_title}>
              {title}
            </div>
            <div className={classes.step_description}>
              {text}
            </div>
          </div>
        </div>
      })}
    </div>
    <LandingButton analyticCategory='landing_delivery_steps' />
  </div>
}

export default DeliverySteps;