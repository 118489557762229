import React from "react"

import { t, T } from "@web-solutions/module-localization";

import { Image, Title } from "@web-solutions/core/ui-elements";

import { useDetectMetricSystem } from "@web-solutions/core/hooks/use-detect-metric-system";

import classes from './style.module.scss';
import { IMAGES } from "./images";

const tKey = 'landing.book_specifications'

export const BookSpecifications: React.FC = () => {
  const metricSystem = useDetectMetricSystem();

  const options = t(`${tKey}.info`, { returnObjects: true, weight: metricSystem === 'imperial' ? '1.98lbs' : '0.9kg' }) as unknown as Array<string>;

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <Title level='h1' className={classes.title}>
          {t(`${tKey}.title`)}
        </Title>
      </div>
      <Image
        className={classes.image}
        img={metricSystem === 'imperial' ? IMAGES.BOOK_IMPERIAL : IMAGES.BOOK_METRIC}
        alt='book'
      />
      <div className={classes.info}>
        {options.map((option, index) => {
          return <div className={classes.option} key={index}>
            <T k={option} />
          </div>
        })}
      </div>
    </div >
  )
}

export default BookSpecifications;