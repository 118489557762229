import { useState, type BaseSyntheticEvent } from 'react';
import { useSelector } from 'react-redux';

import { getPersonalizedData } from 'src/store/profile/selectors';

import { useRemoteConfig } from 'src/hooks/use-remote-config';

import type { PersonalizedData } from 'src/store/profile';

import { BirthDateType, BirthLocationType } from '../types';

import { checkFormValidity, validateFormField, isBirthDateKey } from '../utils';

import { useGetInitialLocation } from './use-get-initial-location'

export const useForm = () => {
  const { birthPlaceData } = useRemoteConfig();

  const formData = useSelector(getPersonalizedData);

  const location = useGetInitialLocation(birthPlaceData.suggestCurrentPlace);

  const initialFormData = {
    name: '',
    last_name: '',
    birth_date: {
      day: '',
      month: '',
      year: '',
    },
    birth_time: {
      hour: '',
      minutes: '',
      dont_now: false,
    },
    location,
    email: '',
  };

  const [isFormValid, setIsFormValid] = useState(false);

  const [data, setData] = useState<PersonalizedData>(() => formData || initialFormData);

  const [validation, setValidation] = useState({
    name: true,
    last_name: true,
    birth_date: true,
    birth_time: true,
    location: true,
    email: true,
  });

  const handleChange = (e: BaseSyntheticEvent) => {
    const { name, value } = e.target;

    if (typeof value === 'string') {
      setData({ ...data, [name]: value });
    }
  };

  const validateFormData = (data: PersonalizedData) => {
    const newValidation = (Object.keys(data) as (keyof PersonalizedData)[]).reduce((acc, fieldName) => {
      const value = data[fieldName];

      const preparedValue = typeof value === 'string' ? value.trim() : value;

      const isValid = validateFormField({ name: fieldName, value: preparedValue });

      return { ...acc, [fieldName]: isValid }
    }, { ...validation })

    return newValidation;
  };

  const validateFieldData = (name: keyof PersonalizedData, value: string | BirthLocationType | BirthDateType) => {
    const preparedValue = typeof value === 'string' ? value.trim() : value;
    const isValid = validateFormField({ name, value: preparedValue });
    const newValidation = { ...validation, [name]: isValid }
    setValidation(newValidation);
  };

  const finalValidation = (value?: any) => {
    const newValidation = validateFormData({...data, location: value || data.location});
    const isFormValid = checkFormValidity(newValidation);
    setIsFormValid(isFormValid);
  }

  const handleBlur = (e: BaseSyntheticEvent) => {
    const { name, value } = e.target;

    if (isBirthDateKey(name)) {
      validateFieldData('birth_date', data.birth_date);
      finalValidation();
    }
    else if (name === 'location') {
      validateFieldData(name, value || data.location);
      finalValidation(value || data.location);
    }
    else {
      validateFieldData(name, value);
      finalValidation();
    }
  };

  return {
    validation,
    data,
    isFormValid,
    handleChange,
    setData,
    validateFieldData,
    handleBlur
  }
}
