import { t } from '@web-solutions/module-localization';

import { ReactComponent as RatingIcon } from '../raiting/rating.svg';

import { ReactComponent as SealCheckIcon } from './sealcheck.svg';

import classes from './style.module.scss';

const tKey = 'landing.readers_say';

interface Props {
  name: string,
  country: string,
  text: string,
  date: string
}

export const Review: React.FC<Props> = ({ name, country, text, date }) => {

  return (
    <div className={classes.review}>
      <div className={classes.top_part}>
        <div className={classes.avatar}>{name.charAt(0).toUpperCase()}</div>
        <div className={classes.top_right_part}>
          <div className={classes.user}>
            <p className={classes.name}>{name}</p>
            <SealCheckIcon />
            <p className={classes.verified}>{t(`${tKey}.verified`)}</p>
          </div>
          <p className={classes.country}>{country}</p>
          <RatingIcon />
        </div>
      </div>
      <p className={classes.text}>{text}</p>
      <p className={classes.date}>{date}</p>
    </div>
  );
}