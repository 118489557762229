import _orderBy from 'lodash/orderBy';

import {
  NATAL_WIDTH,
  NATAL_HEIGHT,
  NATAL_R1,
  NATAL_R2,
  NATAL_R3,
  TRANSIT_R1,
  TRANSIT_R5,
  TRANSIT_WIDTH,
  TRANSIT_HEIGHT,
  TRANSIT_ARC_OFFSETS,
  ASPECTS,
  PLANETS_EXTRA_ORBIS,
  ZODIAC_WHEEL_DEGS,
  HOUSES_TITLES,
} from './constants';

/* UTILS */

// Array items pairs
export const getPairs = arr =>
  arr?.length ? arr.map((v, i) => arr.slice(i + 1).map(w => [v, w])).flat() : [];

// Angles diff
export const getDiff = (a, b) => {
  const phi = Math.abs(a - b) % 360;
  return phi > 180 ? 360 - phi : phi;
};

// Angles middle
export const getMiddle = (a, b) => {
  if (a > b) {
    [a, b] = [b, a];
  }

  if (b - a > 180) {
    b -= 360;
  }

  let result = (b + a) / 2;

  if (result < 0) {
    result += 360;
  }

  return result;
};

// Circle point coords
export const getCirclePointCoords = (w, h, r, angle) => ({
  x: w / 2 + r * Math.cos((angle * Math.PI) / 180),
  y: Math.abs(h / 2 - r * Math.sin((angle * Math.PI) / 180)),
});

/* GENERAL */

// Fixes nulls in data
export const fixObjects = objects => {
  objects.forEach(object => {
    object.dms30 = object.dms30 || 0;
    object.dms360 = object.dms360 || 0;
  });
};

// Bar line coords
export const getBarCoords = (w, h, r1, r2, deg) => {
  const {x: x1, y: y1} = getCirclePointCoords(w, h, r1, deg);
  const {x: x2, y: y2} = getCirclePointCoords(w, h, r2, deg);

  return {
    x1,
    y1,
    x2,
    y2,
  };
};

// 30 degs bars
export const getAngles30 = () => {
  const result = [];
  for (let i = 0; i < 360; i = i + 30) {
    result.push(i);
  }
  return result;
};

// Transforms angles from zodic to standart scale
export const transformAngles = angles =>
  angles?.length ? angles.map(angle => (angle + 180) % 360) : [];

/* NATAL */

// Sorted by angle
export const getSortedPlanets = planets =>
  planets?.length ? _orderBy(planets, 'dms360', 'asc') : [];

// Planet size
export const checkIfPlanetSm = (planet, planets) =>
  planet && planets?.length
    ? planets.some(
        item => item?.name !== planet?.name && getDiff(item.dms360, planet.dms360) < 15,
      )
    : false;

// House/planet angle from zero point
export const getAngleBySign = (obj, asc) =>
  obj && asc
    ? 180 +
      ZODIAC_WHEEL_DEGS[obj.sign] -
      ZODIAC_WHEEL_DEGS[asc.sign] +
      obj.dms30 -
      asc.dms30
    : 0;

// Planets connections
export const getConnections = (planets, houses) => {
  if (!planets?.length || !houses?.length) {
    return [];
  }

  const connections = [];
  const pairs = getPairs(planets);

  pairs.forEach(pair => {
    const diff = getDiff(pair[0].dms360, pair[1].dms360);
    const extraOrbis =
      PLANETS_EXTRA_ORBIS.includes(pair[0]?.name) ||
      PLANETS_EXTRA_ORBIS.includes(pair[1]?.name);

    if (ASPECTS.positive.some(aspect => checkAspect(aspect, diff, extraOrbis))) {
      connections.push({
        coords: getConnectionCoords(pair, houses),
        type: 'harmonic',
      });
    }

    if (ASPECTS.negative.some(aspect => checkAspect(aspect, diff, extraOrbis))) {
      connections.push({
        coords: getConnectionCoords(pair, houses),
        type: 'tense',
      });
    }
  });

  return connections;
};

// Checks if aspect
export const checkAspect = (aspect, diff, extraOrbis) =>
  extraOrbis && aspect.extra_orbis
    ? diff > aspect.diff - aspect.extra_orbis && diff < aspect.diff + aspect.extra_orbis
    : diff > aspect.diff - aspect.orbis && diff < aspect.diff + aspect.orbis;

// Connection coords
export const getConnectionCoords = (pair, houses) => {
  if (!pair?.length || !houses?.length) {
    return {};
  }

  const {x: x1, y: y1} = getNatalPlanetCoords(pair[0], houses);
  const {x: x2, y: y2} = getNatalPlanetCoords(pair[1], houses);

  return {
    x1,
    y1,
    x2,
    y2,
  };
};

// Get planet coords
export const getNatalPlanetCoords = (planet, houses) =>
  planet && houses?.length
    ? getCirclePointCoords(
        NATAL_WIDTH,
        NATAL_HEIGHT,
        NATAL_R1,
        getAngleBySign(planet, houses[0]),
      )
    : {};

// Wheel position
export const getWheelDeg = houses =>
  houses?.length ? 180 + ZODIAC_WHEEL_DEGS[houses[0].sign] + houses[0].dms30 : 0;

// Wheel bars
export const getWheelBars = houses =>
  houses?.length
    ? getAngles30().map(angle => ({
        coords: getBarCoords(
          NATAL_WIDTH,
          NATAL_HEIGHT,
          NATAL_R1 + 0.75,
          NATAL_R2 - 0.75,
          angle - houses[0].dms30,
        ),
      }))
    : [];

// House bars
export const getHousesBars = houses =>
  houses?.length
    ? houses.map((house, i) => {
        const angle = getAngleBySign(house, houses[0]) || 0;
        const titleAngle = 360 - (angle - 90) + 1.5;

        return {
          coords: getBarCoords(NATAL_WIDTH, NATAL_HEIGHT, NATAL_R1, NATAL_R3, angle),
          title: HOUSES_TITLES[i],
          titleRotation: titleAngle,
        };
      })
    : [];

/* TRANSIT */

// Signs bars
export const getSignsBars = () =>
  getAngles30().map(angle => ({
    coords: getBarCoords(TRANSIT_WIDTH, TRANSIT_HEIGHT, TRANSIT_R1, TRANSIT_R5, angle),
  }));

// Get planet coords
export const getTransitPlanetCoords = (dms360, r, offset = 0) => {
  const {x, y} = getCirclePointCoords(TRANSIT_WIDTH, TRANSIT_HEIGHT, r, 180 + dms360);
  return {
    x: x + offset,
    y: y + offset,
  };
};

// Gets planet dms360
export const getDms360ByPlanetName = (name, planets) =>
  name && planets?.length ? planets.find(planet => planet.name === name)?.dms360 : 0;

// Arc
export const getTransitArc = (r, startAngle, endAngle) => {
  [startAngle, endAngle] = transformAngles([startAngle, endAngle]);

  startAngle = startAngle + TRANSIT_ARC_OFFSETS[r].start;
  endAngle = endAngle + TRANSIT_ARC_OFFSETS[r].end;

  const start = getCirclePointCoords(TRANSIT_WIDTH, TRANSIT_HEIGHT, r, startAngle);
  const end = getCirclePointCoords(TRANSIT_WIDTH, TRANSIT_HEIGHT, r, endAngle);
  let largeArcFlag = 0;

  if (endAngle > startAngle) {
    largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1;
  } else {
    largeArcFlag = startAngle - endAngle <= 180 ? 1 : 0;
  }

  return ['M', start.x, start.y, 'A', r, r, 1, largeArcFlag, 0, end.x, end.y].join(' ');
};

// Sector
export const getTransitSector = (startAngle, endAngle) => {
  [startAngle, endAngle] = transformAngles([startAngle, endAngle]);

  const start = getCirclePointCoords(
    TRANSIT_WIDTH,
    TRANSIT_HEIGHT,
    TRANSIT_R5,
    startAngle,
  );
  const end = getCirclePointCoords(TRANSIT_WIDTH, TRANSIT_HEIGHT, TRANSIT_R5, endAngle);
  let sweepFlag = 0;

  if (endAngle > startAngle) {
    sweepFlag = endAngle - startAngle <= 180 ? 0 : 1;
  } else {
    sweepFlag = startAngle - endAngle <= 180 ? 1 : 0;
  }

  return [
    'M',
    TRANSIT_WIDTH / 2,
    TRANSIT_HEIGHT / 2,
    'L',
    start.x,
    start.y,
    'A',
    TRANSIT_R5,
    TRANSIT_R5,
    0,
    0,
    sweepFlag,
    end.x,
    end.y,
    'Z',
  ].join(' ');
};

// Aspect title rotation
export const getAspectTitleRotation = (startAngle, endAngle) => {
  [startAngle, endAngle] = transformAngles([startAngle, endAngle]);
  return 90 - ((startAngle + endAngle) % 360) / 2;
};

// Aspect title position
export const getAspectTitleY = (startAngle, endAngle) => {
  [startAngle, endAngle] = transformAngles([startAngle, endAngle]);
  return getMiddle(startAngle, endAngle) < 180 ? 46 : TRANSIT_HEIGHT - 42;
};
