import classNames from 'classnames';

import classes from './style.module.scss';

const skeletonCount = 3;

interface Props {
  className?: string;
}

export const ZodiacSkeleton: React.FC<Props> = ({ className }) => {
  return (
    <div className={classNames(classes.skeleton, className)}>
      {Array.from({ length: skeletonCount }, (_, index) => (
        <div key={index} className={classes.sign}>
          <div className={classes.icon} />
          <div className={classes.name} />
          <div className={classes.planet} />
        </div>
      ))}
    </div>
  )

}