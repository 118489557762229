import type { FC } from 'react'
import { useDispatch } from 'react-redux';

import Analytics from '@web-solutions/module-analytics'

import { clearPersonalizedData } from 'src/store/profile/actions';
import { ROUTES } from 'src/constants/routes';
import { useShopNavigation } from 'src/hooks/use-shop-navigation';
import { deleteProduct } from 'src/store/cart/actions';
import { ShopProduct } from 'src/store/cart/types';

import { TrashIcon } from './icons'

import classes from './style.module.scss'

interface Props {
  product: ShopProduct
}

export const DeleteButton: FC<Props> = ({ product }) => {
  const dispatch = useDispatch()

  const { navigateTo } = useShopNavigation();

  const handleDeleteClick = () => {
    Analytics.trackEvent('cart_product', 'removed', { product: product.id })
    dispatch(deleteProduct(product));
    dispatch(clearPersonalizedData());
    navigateTo(ROUTES.MAIN);
  }

  return (
    <button
      className={classes.deleteButton}
      onClick={handleDeleteClick}
    >
      <TrashIcon className={classes.icon} />
    </button>
  )
}
