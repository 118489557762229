import { t } from '@web-solutions/module-localization';

//@ts-ignore
import video from './video.mp4';
import Poster from './poster.webp';

import classes from './style.module.scss';

const tKey = 'landing.cosmic_guidance';

const CosmicGuidance = () => {
  return (
    <div className={classes.wrapper}>
      <p className={classes.title}>{t(`${tKey}.title`)}</p>
      <video
        autoPlay
        loop
        muted
        playsInline
        className={classes.video}
        poster={Poster}
      >
        <source src={video} type="video/mp4" />
      </video>
    </div>
  );
}

export default CosmicGuidance;