import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { t } from '@web-solutions/module-localization';
import { SIGNS_ICONS_MATTE } from "@web-solutions/natal-chart/signs-icons"

import { getPersonalizedData } from 'src/store/profile/selectors';

import { ZodiacSkeleton } from 'src/components/skeleton';

import { PLANET_IDS, PLANETS } from './constants';

import { useHousesAndPlanetsData } from './hooks/useHousesAndPlanetsData';

import classes from './style.module.scss';
import './style.scss'

const tKey = 'landing.birth_chart';

interface Props {
  className?: string;
  skeletonClassName?: string;
  containerClassName?: string;
}

export const Signs: React.FC<Props> = ({ className, containerClassName, skeletonClassName }) => {
  const personalizedData = useSelector(getPersonalizedData);

  const housesAndPlanets = useHousesAndPlanetsData(personalizedData);

  const planets = housesAndPlanets?.reduce((acc, curr) => {
    if (PLANETS.includes(curr.name?.toLowerCase() as PLANET_IDS)) {
      return [curr, ...acc]
    }
    return acc
  }, [] as typeof housesAndPlanets)

  return (
    <div className={classNames(classes.container, containerClassName)}>
      <SwitchTransition >
        <CSSTransition key={String((!personalizedData || !housesAndPlanets))} timeout={1000} classNames="sign">
          <div>
            {(!personalizedData || !housesAndPlanets) ?
              <ZodiacSkeleton className={classNames(skeletonClassName)} />
              :
              <div className={classNames(classes.signs, className)}>
                {planets?.map((item) => {
                  const signImageSource = SIGNS_ICONS_MATTE[item?.sign?.toLowerCase() as keyof typeof SIGNS_ICONS_MATTE];
                  const tPlanet = item?.name?.toLowerCase();

                  return <div key={item?.name} className={classes.sign}>
                    <img src={signImageSource?.src} srcSet={signImageSource?.srcSet} alt='sign' className={classes.img} />
                    <p className={classes.name}>{t(`ELEMENTS.${item.sign.toUpperCase()}`)}</p>
                    <p className={classes.planet}>{t(`${tKey}.${tPlanet}`)}</p>
                  </div>
                })}
              </div>
            }
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  )
}