import { ImagesMap } from 'core/interfaces/images';

import MERCURY_1X from './mercury.webp'
import MERCURY_2X from './mercury@2x.webp'
import MERCURY_3X from './mercury@3x.webp'

import VENUS_1X from './venus.webp'
import VENUS_2X from './venus@2x.webp'
import VENUS_3X from './venus@3x.webp'

import MARS_1X from './mars.webp'
import MARS_2X from './mars@2x.webp'
import MARS_3X from './mars@3x.webp'

import JUPITER_1X from './jupiter.webp'
import JUPITER_2X from './jupiter@2x.webp'
import JUPITER_3X from './jupiter@3x.webp'

import SATURN_1X from './saturn.webp'
import SATURN_2X from './saturn@2x.webp'
import SATURN_3X from './saturn@3x.webp'

import URANUS_1X from './uranus.webp'
import URANUS_2X from './uranus@2x.webp'
import URANUS_3X from './uranus@3x.webp'

import NEPTUNE_1X from './neptune.webp'
import NEPTUNE_2X from './neptune@2x.webp'
import NEPTUNE_3X from './neptune@3x.webp'

import PLUTO_1X from './pluto.webp'
import PLUTO_2X from './pluto@2x.webp'
import PLUTO_3X from './pluto@3x.webp'


export const IMAGES: ImagesMap = {
  mercury: {
    src: MERCURY_1X,
    srcSet:`${MERCURY_1X}, ${MERCURY_2X} 2x, ${MERCURY_3X} 3x`
  },
  venus: {
    src: VENUS_1X,
    srcSet:`${VENUS_1X}, ${VENUS_2X} 2x, ${VENUS_3X} 3x`
  },
  mars: {
    src: MARS_1X,
    srcSet:`${MARS_1X}, ${MARS_2X} 2x, ${MARS_3X} 3x`
  },
  jupiter: {
    src: JUPITER_1X,
    srcSet:`${JUPITER_1X}, ${JUPITER_2X} 2x, ${JUPITER_3X} 3x`
  },
  saturn: {
    src: SATURN_1X,
    srcSet:`${SATURN_1X}, ${SATURN_2X} 2x, ${SATURN_3X} 3x`
  },
  uranus: {
    src: URANUS_1X,
    srcSet:`${URANUS_1X}, ${URANUS_2X} 2x, ${URANUS_3X} 3x`
  },
  neptune: {
    src: NEPTUNE_1X,
    srcSet:`${NEPTUNE_1X}, ${NEPTUNE_2X} 2x, ${NEPTUNE_3X} 3x`
  },
  pluto: {
    src: PLUTO_1X,
    srcSet:`${PLUTO_1X}, ${PLUTO_2X} 2x, ${PLUTO_3X} 3x`
  }
}