import {
  AQUARIUS,
  ARIES,
  CANCER,
  CAPRICORN,
  GEMINI,
  LEO,
  LIBRA,
  PISCES,
  SAGITTARIUS,
  SCORPIO,
  TAURUS,
  VIRGO,
} from './signs-icons';

const MOON = 'moon';
const SUN = 'sun';

/* Natal chart */
export const NATAL_WIDTH = 320;
export const NATAL_HEIGHT = 320;
export const NATAL_CENTER = `${NATAL_WIDTH / 2}, ${NATAL_HEIGHT / 2}`;
export const NATAL_VIEWBOX = `0 0 ${NATAL_WIDTH} ${NATAL_HEIGHT}`;

export const NATAL_R1 = 84;
export const NATAL_R2 = 112;
export const NATAL_R3 = 136;

/* Transit chart */
export const TRANSIT_WIDTH = 280;
export const TRANSIT_HEIGHT = 280;
export const TRANSIT_CENTER = `${TRANSIT_WIDTH / 2}, ${TRANSIT_HEIGHT / 2}`;
export const TRANSIT_VIEWBOX = `0 0 ${TRANSIT_WIDTH} ${TRANSIT_HEIGHT}`;

export const TRANSIT_R1 = 24;
export const TRANSIT_R2 = 52;
export const TRANSIT_R3 = 66;
export const TRANSIT_R4 = 80;
export const TRANSIT_R5 = 108;

export const TRANSIT_ARC_OFFSETS = {
  [TRANSIT_R2]: {start: 0, end: -20},
  [TRANSIT_R3]: {start: 0, end: -16},
  [TRANSIT_R4]: {start: 0, end: -13},
};

/* General */

export const ASPECTS = {
  positive: [
    {diff: 30, orbis: 1.5},
    {diff: 60, orbis: 5},
    {diff: 120, orbis: 5, extra_orbis: 7},
    {diff: 150, orbis: 1.5},
  ],
  negative: [
    {diff: 45, orbis: 1.5},
    {diff: 90, orbis: 5},
    {diff: 135, orbis: 1.5},
    {diff: 180, orbis: 7},
  ],
};

export const PLANETS_EXTRA_ORBIS = [SUN, MOON];

export const ZODIAC_WHEEL_DEGS = {
  [LIBRA]: 0,
  [SCORPIO]: 30,
  [SAGITTARIUS]: 60,
  [CAPRICORN]: 90,
  [AQUARIUS]: 120,
  [PISCES]: 150,
  [ARIES]: 180,
  [TAURUS]: 210,
  [GEMINI]: 240,
  [CANCER]: 270,
  [LEO]: 300,
  [VIRGO]: 330,
};

export const HOUSES_TITLES = [
  'Asc',
  'II',
  'III',
  'IV',
  'V',
  'VI',
  'Dsc',
  'VIII',
  'IX',
  'X',
  'XI',
  'XII',
];
