import React from 'react';
import classNames from 'classnames';

import CircleProgress from 'core/ui-elements/circle';
import { useMagicProgress } from 'core/hooks/use-magic-progress';

import { ProgressLine } from './components/progress-line';

import classes from './style.module.scss';

interface Props {
  type?: "line" | "circle",
  trailColor: string,
  fillColor: string,
  captionClassName?: string,
  className?: string,
  onProgress: (progress: number) => void,
  onProgressFinish: () => void,
  duration: number,
  startDelay?: number
}

export const Progress: React.FC<Props> = ({
  trailColor,
  fillColor,
  captionClassName,
  className,
  onProgress,
  onProgressFinish,
  duration,
  startDelay,
  type = 'circle',
}) => {
  const { progress } = useMagicProgress({ duration, startDelay, onProgress, onProgressFinish });

  return (
    <div className={classNames(classes.progress, className)}>
      {type === 'line'
        ? (
          <div className={classes.line_progress}>
            <p className={classes.progress}>
              {progress}
              <span className={classes.percent}>%</span>
            </p>
            <ProgressLine
              progress={progress}
              lineColor={fillColor}
              trailColor={trailColor}
            />
          </div>
        )
        :
        (
          <div className={classNames(classes.circular_progress, classes.wrapper)}>
            <CircleProgress
              value={progress}
              ringColor={fillColor}
              trailColor={trailColor}
            >
              <h3 className={classNames(classes.caption, captionClassName)}>
                {progress}
                <span className={classes.percent}>%</span>
              </h3>
            </CircleProgress>
          </div>
        )
      }

    </div>
  );
};
