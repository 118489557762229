import type { FC, PropsWithChildren, ComponentProps } from 'react'
import classNames from 'classnames'

import { ArrowIcon } from './icons'

import classes from './style.module.scss'

interface NavHeaderBaseProps extends PropsWithChildren<ComponentProps<'header'>> {
  withBorder?: boolean;
  onBackClick: () => void;
}

export const NavHeaderBase: FC<NavHeaderBaseProps> = ({ withBorder = false, className, onBackClick, children, ...props }) => {

  const handleClick = () => {
    onBackClick();
  }

  return (
    <header className={classNames(classes.orderNavWrapper, className, { [classes.withBorder]: withBorder })} {...props}>
      <ArrowIcon className={classes.icon} onClick={handleClick} />
      {children}
    </header>
  )
}