import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getApproximateLocation } from 'src/store/profile/selectors';

export const useGetInitialLocation = (defaultEnabled: boolean) => {
  const { label = '', lat = '', lon = '' } = useSelector(getApproximateLocation) || {};

  const location = useMemo(() => defaultEnabled ? { label, lat, lon } : { label: '', lat: '', lon: '' }, [label, lat, lon, defaultEnabled])

  return location
}