import { useEffect } from 'react';

export const useFixedLanding = (isPersonalizationPopupVisible: boolean) => {
  useEffect(() => {
    if (isPersonalizationPopupVisible) {
      document.body.style.position = 'fixed';
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.position = 'static';
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
      document.body.style.position = 'static';
    };
  }, [isPersonalizationPopupVisible]);
}