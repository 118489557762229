import React from 'react';
import { useLocation, Routes } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { useScrollOnLocationChange } from '@web-solutions/core/hooks/use-scroll-on-location-change'

import './styles.scss';

interface PageTransitionProps {
  children: React.ReactNode,
}

export const PageTransition: React.FC<PageTransitionProps> = ({ children }) => {
  const location = useLocation();

  useScrollOnLocationChange({ mode: 'root' });

  return (
    <TransitionGroup className='transition-group'>
      <CSSTransition
        //TODO: add page transitions for all app
        key={location.pathname.split('/')[1] === 'magic' ? 'magic' : 'default'}
        timeout={800}
        classNames="page"
        unmountOnExit
      >
        <Routes location={location}>
          {children}
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  )
}
