import dayjs from 'dayjs';

import { type PersonalizedData } from 'src/store/profile';


export const getFormattedData = (personalizedData: PersonalizedData | null) => {
  const date = dayjs(`${personalizedData?.birth_date.year}-${personalizedData?.birth_date.month}-${personalizedData?.birth_date.day}`);
  const formattedDate = date.format('MMMM D, YYYY');
  const dayOfWeek = dayjs(formattedDate).format('dddd');
  const formattedTime = personalizedData?.birth_time.hour ? `${personalizedData?.birth_time.hour}:${personalizedData?.birth_time.minutes}` : '';

  return {
    formattedDate,
    dayOfWeek,
    formattedTime
  }
}