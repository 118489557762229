import type { ImagesMap } from '@web-solutions/core/interfaces/images'

import BG from './bg.webp'
import BG2X from './bg@2x.webp'
import BG3X from './bg@3x.webp'

import BG_PERSONOLIZED_1X from './p_bg.webp'
import BG_PERSONOLIZED_2X from './p_bg@2x.webp'
import BG_PERSONOLIZED_3X from './p_bg@3x.webp'

export const IMAGES_WELCOME: ImagesMap = {
  BG: {
    src: BG,
    srcSet: `${BG} 1x, ${BG2X} 2x, ${BG3X} 3x`,
  },
  BG_PERSONOLIZED: {
    src: BG_PERSONOLIZED_1X,
    srcSet: `${BG_PERSONOLIZED_1X} 1x, ${BG_PERSONOLIZED_2X} 2x, ${BG_PERSONOLIZED_3X} 3x`,
  }
}