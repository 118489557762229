import React from 'react';

import classes from './style.module.scss';

interface Props {
  progress: number;
  lineColor: string;
  trailColor: string;
}


export const ProgressLine: React.FC<Props> = ({progress, lineColor, trailColor}) => {
  return (
    <div className={classes.progress_line}  style={{backgroundColor:`${trailColor}`}}>
      <div className={classes.progress}
        style={{
          width: `${progress}%`,
          background:`${lineColor}`
        }}
      />
    </div>
  )

}