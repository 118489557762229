import type { FC } from 'react'
import { useSelector } from 'react-redux'

import { default as CorePaymentForm } from '@web-solutions/core/payment/components/payment-form'
import { Preloader } from '@web-solutions/core/ui-elements';

import type { BaseFormConfig } from 'src/billing/payment-processor/types'
import type { RootState } from 'src/store/types'

import { PaymentCardWrapper } from './components'

import classes from './style.module.scss'

export const PaymentForm: FC<BaseFormConfig['props']> = (props) => {
  const isOrderPending = useSelector((state: RootState) => state.billing.orderPending)

  return (
    <PaymentCardWrapper>
      {isOrderPending && (
        <Preloader className={classes.preloader} />
      )}
      <CorePaymentForm {...props} />
    </PaymentCardWrapper>
  )
}