import { useSelector, useDispatch } from 'react-redux';

import Analytics from '@web-solutions/module-analytics';

import { usePersonalizationPopupContext } from "src/hooks/use-personalization-popup-context";
import { EVENT_ACTION } from 'src/constants/events';
import { useRemoteConfig } from "src/hooks/use-remote-config";
import { getPersonalizedData } from 'src/store/profile/selectors';
import { ROUTES } from 'src/constants/routes';
import { useShopNavigation } from 'src/hooks/use-shop-navigation';
import { setCartProducts } from 'src/store/cart/actions';
import { selectCartProducts } from 'src/store/cart/selectors';

import Button from '../button';

import { Price } from './components/price';
import { NoPrice } from './components/no-price';

interface LandingButtonProps {
  analyticCategory: string;
  className?: string;
}

export const LandingButton: React.FC<LandingButtonProps> = ({ analyticCategory, className }) => {
  const { shopMode, product } = useRemoteConfig();
  const dispatch = useDispatch();

  const { navigateTo } = useShopNavigation();

  const { onOpen } = usePersonalizationPopupContext();

  const personalizedData = useSelector(getPersonalizedData);
  const cartProducts = useSelector(selectCartProducts)

  const isPersonalized = !!personalizedData

  const handleOpen = () => {
    if (isPersonalized) {
      Analytics.trackEvent('cart', EVENT_ACTION.OPEN, { openingPlace: analyticCategory });
      if (shopMode === 'manual_add_to_cart' && !cartProducts.length) {
        dispatch(setCartProducts(([product])));
      }
      navigateTo(ROUTES.CART);
    } else {
      Analytics.trackEvent(analyticCategory, EVENT_ACTION.CLICK);
      onOpen();
    }
  }

  return (
    <Button className={className} onClick={handleOpen}>
      {shopMode === 'auto_add_to_cart' ?
        <Price isPersonalized={isPersonalized} />
        :
        <NoPrice isPersonalized={isPersonalized} />
      }
    </Button>
  );
};