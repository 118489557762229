export const AUTOCOMPOLETE_EMAIL_DOMAINS = [
  '@gmail.com',
  '@yahoo.com',
  '@hotmail.com',
  '@icloud.com',
  '@aol.com',
  '@outlook.com',
  '@hotmail.co.uk',
  '@live.com',
  '@yahoo.co.uk',
  '@msn.com',
];
