import type { FC } from 'react';

import classnames from 'classnames'

import { Checkbox as CoreCheckbox, type CheckboxProps as CoreCheckboxProps } from '@web-solutions/core/ui-elements';

import classes from './style.module.scss';

interface CheckboxWithLabelProps extends CoreCheckboxProps {
  text: string | React.ReactNode;
}

export const CheckboxWithLabel: FC<CheckboxWithLabelProps> = ({ text, className, classNameCheckbox, ...props }) => (
  <label className={classes.checkboxWrapper}>
    <CoreCheckbox
      className={classnames(classes.checkboxInput, className)}
      classNameCheckbox={classnames(classes.checkboxSpan, classNameCheckbox)}
      {...props}
    />
    <p className={classes.text}>{text}</p>
  </label>
);