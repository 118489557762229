import React from 'react';

import * as COLORS from './colors';

const NatalChartPlanet = ({ coords, name, sm, circleColor=COLORS.DARK_BLUE_GREY, strokeWidth }) => {

  const { x, y } = coords;

  return (
    <g>
      <circle
        cx={x}
        cy={y}
        r={sm ? 7 : 11}
        fill="url(#planetGrad)"
        stroke={sm ? circleColor : ''}
        strokeWidth={strokeWidth}
      />
      {sm
        ? (
          <use href={`#${name}`} x={x - 5} y={y - 5} width={10} height={10} />
        )
        : (
          <use href={`#${name}`} x={x - 8} y={y - 8} width={16} height={16} />
        )}
    </g>
  );

}

export default NatalChartPlanet;
