import React from "react";

import BookLanding from 'src/screens/book-landing';
import { OrderSuccess } from 'src/screens/order/order-success';
import { Shipping } from "src/screens/shipping";
import Cart from "src/screens/cart";
import Magic from "src/screens/magic";

import { ROUTES } from "./routes";

export const SCREENS = {
  [ROUTES.MAIN]: <BookLanding />,
  [ROUTES.SHIPPING]: <Shipping />,
  [ROUTES.SUCCESS]: <OrderSuccess />,
  [ROUTES.CART]: <Cart />,
  [ROUTES.MAGIC]: <Magic />
};