import { type FC, useEffect, useState } from 'react';
import classNames from 'classnames'

import { t } from "@web-solutions/module-localization";
import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { Progress } from '@web-solutions/core/containers/magic-progress';
import { Title, Text } from '@web-solutions/core/ui-elements';

import { cacheImagesCascade } from '@web-solutions/core/utils/images';

import { ROUTES } from 'src/constants/routes'

import { Signs } from 'src/components/signs';

import { IMAGES_WELCOME } from 'src/screens/book-landing/sections/welcome/images'

import { useRemoteConfig } from 'src/hooks/use-remote-config';
import { useShopNavigation } from 'src/hooks/use-shop-navigation';
import { useGetProcessedInfo } from 'src/components/product-and-customer-info/hooks';

import { ANALYTICS } from './constants'

import classes from './style.module.scss';

const Magic: FC = () => {
  const [stage, setStage] = useState(0);
  const [isFinished, setFinished] = useState(false);

  const { magicDurationLoad = 12000 } = useRemoteConfig();

  const { birthDateFullInfo, location } = useGetProcessedInfo();

  const { navigateTo } = useShopNavigation();

  useEffect(() => {
    Analytics.trackEvent(ANALYTICS, EVENT_ACTION.OPEN);

    cacheImagesCascade([IMAGES_WELCOME.BG_PERSONOLIZED])
  }, []);

  const handleFinish = () => {
    setFinished(true)

    setTimeout(() => {
      Analytics.trackEvent(ANALYTICS, EVENT_ACTION.SUCCESS);

      navigateTo(ROUTES.MAIN)
    }, 1000)
  }

  return (
    <div className={classNames(classes.magicWrapper, { [classes.finished]: isFinished })}>
      <div className={classNames(classes.topWrapper, { [classes.finished]: isFinished })}>
        <Title level='h3' className={classes.title}>
          {t('magic.title')}
        </Title>
        <Text className={classNames(classes.subtitle, { [classes.completed]: stage >= 17 })}>
          {birthDateFullInfo}
          <br />
          {location?.label && t('customer.born_in', { location: location?.label })}
        </Text>
      </div>
      <Progress
        className={classNames(classes.progress, { [classes.completed]: stage >= 99, [classes.finished]: isFinished })}
        duration={magicDurationLoad}
        startDelay={1500}
        fillColor='#CC933D'
        trailColor='rgba(130, 81, 8, 0.10)'
        onProgress={setStage}
        onProgressFinish={handleFinish}
      />
      <Signs
        className={classNames(classes.signs, { [classes.completed]: stage >= 42 })}
        containerClassName={classNames(classes.signContainer, { [classes.finished]: isFinished })}
        skeletonClassName={classNames(classes.signsSkeleton, { [classes.completed]: stage >= 42 })}
      />
    </div>
  )
}

export default Magic