import { useCallback, useEffect, useState } from "react";

import { DELAY_BEFORE_REDIRECT, FINAL_PROGRESS_STEP, INITIAL_PROGRESS, PROGRESS_STEP } from "core/containers/magic-progress/constants";

interface Props {
  duration: number;
  startDelay?: number,
  onProgressFinish: () => void;
  onProgress: (progress: number) => void;
}

export const useMagicProgress = ({ duration, startDelay, onProgress, onProgressFinish }: Props) => {
  const [progress, setProgress] = useState(INITIAL_PROGRESS);
  const pace = duration / FINAL_PROGRESS_STEP;

  const updatePercentage = useCallback(() => {
    onProgress(progress);
    const timer = setTimeout(() => {
      setProgress(progress + PROGRESS_STEP);
    }, pace);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress, pace]);

  useEffect(() => {
    if (progress < FINAL_PROGRESS_STEP) {
      if (progress === INITIAL_PROGRESS && startDelay) {
        setTimeout(() => updatePercentage(), startDelay)
      } else {
        updatePercentage();
      }
    } else {
      setTimeout(onProgressFinish, DELAY_BEFORE_REDIRECT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress, updatePercentage]);

  return {
    progress,
  };
}