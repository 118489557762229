import React from "react"

import { t } from "@web-solutions/module-localization";
import { Image, Text, Title } from "@web-solutions/core/ui-elements";

import { LandingButton } from "src/components/landing-button";

import classes from './style.module.scss';
import { IMAGES } from "./images";

const tKey = 'landing.share_joy'

export const ShareJoy: React.FC = () => {

  return <div className={classes.wrapper}>
    <div className={classes.content}>
      <Title level='h1' className={classes.title}>
        {t(`${tKey}.title`)}
      </Title>
      <Text className={classes.subtitle}>
        {t(`${tKey}.subtitle`)}
      </Text>
    </div>
    <Image className={classes.image} alt='book' img={IMAGES.CUSTOMER} />
    <LandingButton className={classes.button} analyticCategory='landing_share_joy' />
  </div>
}

export default ShareJoy;