import { useState, useEffect, useCallback, useRef } from 'react'
import { useNavigate } from 'react-router-dom';

import Analytics from '@web-solutions/module-analytics';
import { t } from '@web-solutions/module-localization';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { Title } from '@web-solutions/core/ui-elements';

import { Subscription } from 'core/interfaces/billing';

import { ROUTES } from 'src/constants/routes';

import { OneTimePayment, type OneTimePaymentRef } from 'src/billing/one-time-payment'

import { ErrorBlock } from '../error_block';

import { useGetPaymentData } from './hooks';

import classes from './style.module.scss'

const ANALYTICS = 'onetime_purchase'

export const PaymentMethod: React.FC = () => {
  const navigateTo = useNavigate();

  const paymentRef = useRef<OneTimePaymentRef>(null);

  const { product, metadata } = useGetPaymentData();

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    window.scrollBy({
      top: -window.innerHeight,
      behavior: 'smooth'
    });
  }, []);

  useEffect(() => {
    const analyticsParams = {
      productId: product?.id,
      currency: product?.currency,
      value: product?.amount,
    }

    Analytics.trackEvent(ANALYTICS, EVENT_ACTION.MOUNT, analyticsParams);
    Analytics.prepareOneTimePurchaseExternalEvents(analyticsParams)
  }, [product?.id, product?.amount, product?.currency]);

  const handlePaymentError = useCallback((error: any) => {
    Analytics.trackEvent(ANALYTICS, EVENT_ACTION.ERROR, {
      type: 'order',
      message: error?.message,
      code: error?.code,
      productId: product?.id,
      currency: product?.currency,
      amount: product?.amount,
      method: error?.method,
    });

    paymentRef.current?.createOrders()

    if (error?.message) {
      setErrorMessage(t('common.payment_error', { error: error?.message }))
    }
  }, [product])

  const handlePaymentSuccess = useCallback((purchase: Subscription) => {
    Analytics.trackOneTimePurchaseEvent({
      type: 'order',
      currency: product?.currency!,
      amount: product?.amount!,
      productId: product?.id!,
      trigger: '',
      //@ts-ignore
      transactionId: purchase?.order?.order_id || purchase?.order_id,
      method: purchase?.method,
      paymentSystem: purchase?.paymentSystem,
    });

    setErrorMessage('')

    navigateTo(
      {
        pathname: ROUTES.SUCCESS,
        search: window.location.search
      },
      {
        replace: true
      })
  }, [product, navigateTo])

  return (
    <div className={classes.paymentMethodWrapper}>
      <Title className={classes.title}>
        {t('payment_method.title')}
      </Title>
      <OneTimePayment
        ref={paymentRef}
        activeProduct={product}
        orderMetadata={metadata}
        onError={handlePaymentError}
        onSuccess={handlePaymentSuccess}
      />
      {
        Boolean(errorMessage) &&
        (
          <ErrorBlock className={classes.errorBlock} text={errorMessage} />
        )
      }
    </div>
  )
}