import type { FC } from 'react'

import classNames from 'classnames'

import { Button as CoreButton, type ButtonProps as CoreButtonProps } from '@web-solutions/core/ui-elements';

import classes from './style.module.scss'

interface OrderButtonProps extends CoreButtonProps { }

export const OrderButton: FC<OrderButtonProps> = ({ className, titleClassName, title, children, ...props }) => (
  <CoreButton
    className={classNames(classes.orderButton, className)}
    titleClassName={classNames(classes.orderButtonTitle, titleClassName)}
    title={title}
    {...props}
  >
    {children}
  </CoreButton >
);