export const PUBLIC_ROUTES = {
  MAIN: '/',
  MAGIC: '/magic',
}

export const PROTECTED_ROUTES = {
  SUCCESS: '/success',
  SHIPPING: '/shipping',
  CART: '/cart',
}

export const ROUTES = {
  ...PROTECTED_ROUTES,
  ...PUBLIC_ROUTES
};