import { useState } from 'react';

import { t } from '@web-solutions/module-localization';

import { Raiting } from './components/raiting';
import { Review } from './components/review';

import classes from './style.module.scss';

const tKey = 'landing.readers_say';

type Reviews = {
  name: string,
  country: string,
  text: string,
  date: string
}

const ReadersSay = () => {
  const [expanded, setExpanded] = useState(false);
  const reviews = t(`${tKey}.reviews`, { returnObjects: true }) as any as Reviews[];

  return (
    <div className={classes.wrapper} id='reviews'>
      <p className={classes.title}>{t(`${tKey}.title`)}</p>
      <Raiting />
      <div className={classes.reviews}>
        {
          reviews.map(({ name, country, text, date }, i) => {
            if (!expanded && i > 4) {
              return null
            }

            return (
              <Review
                name={name}
                country={country}
                text={text}
                date={date}
                key={name}
              />
            )
          })
        }
      </div>
      <button onClick={() => setExpanded(!expanded)}>
        {t(`${tKey}.${expanded ? 'less_btn' : 'more_btn'}`)}
      </button>
    </div>
  );
}

export default ReadersSay;