import React, { type BaseSyntheticEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { t, T } from '@web-solutions/module-localization';
import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { Select } from '@web-solutions/core/ui-elements';
import { InputPhone } from '@web-solutions/core/containers/phone-input';
import { PlaceAutocomplete } from '@web-solutions/core/containers/place-picker/components';

import { useGetDebouncedValue } from '@web-solutions/core/hooks/use-get-debounced-value';

import type { PlaceData } from '@web-solutions/core/containers/place-picker/types';

import { processEmail, setShippingInformation } from 'src/store/profile/actions';

import { InputWrapper } from 'src/screens/components/input-wrapper';
import { EmailInput } from 'src/screens/components/email-input';
import { CheckboxWithLabel } from 'src/screens/components/checkbox-with-label';

import { prepareFormDataToSave } from 'src/utils';

import { useRemoteConfig } from 'src/hooks/use-remote-config'

import { CompleteButton } from '../complete_button';

import { useForm } from '../../hooks/use-form';

import { checkFormValidity, getFormattedCity, getSearchQuery } from './utils';

import classes from './style.module.scss';
import 'react-phone-input-2/lib/style.css'

const tKey = 'shipping_information';

interface Props {
  onChangeStatus: () => void;
}

export const ShippingInformation: React.FC<Props> = ({ onChangeStatus }) => {
  const dispatch = useDispatch();
  const {
    validation,
    countries,
    states,
    data,
    isNeedState,
    handleChange,
    handleCheckChange,
    setValidation,
    setData,
  } = useForm();

  const {
    policyLinks,
  } = useRemoteConfig();

  const [focusedFields, setFocusedFields] = useState({
    country: false,
    state: false,
    phone: false,
    city: false,
  });

  const debouncedCityValue = useGetDebouncedValue(data.city.trim());

  const isFormValid = checkFormValidity(validation, data, isNeedState);

  const handleFocus = (e: BaseSyntheticEvent) => {
    const { name } = e.target;

    setFocusedFields((prev) => ({ ...prev, [name]: true }))
  }

  const handleBlur = (e: BaseSyntheticEvent) => {
    const { name } = e.target;

    setTimeout(() => setFocusedFields((prev) => ({ ...prev, [name]: false })), 200);
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    Analytics.trackEvent('shipping_information', EVENT_ACTION.SUBMIT, { data });

    const { email, name, last_name, country, state, city, street, apartment, zip_code, phone, checks } = prepareFormDataToSave(data);

    dispatch(setShippingInformation({ email, name, last_name, country, state, city, street, apartment, zip_code, phone, checks }));
    dispatch(processEmail(email));

    onChangeStatus();
  }

  const handleCitySelect = (v: PlaceData) => {
    //@ts-ignore
    handleChange({ target: { name: 'city', value: v?.label ?? data.city } });
  }

  const commonProps = {
    onChange: handleChange,
    onFocus: handleFocus,
    onBlur: handleBlur,
    inside: true,
    data,
    tKey,
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <p className={classes.title}>{t(`${tKey}.title`)}</p>
      <div className={classes.contact}>
        <p className={classes.group_title}>{t(`${tKey}.contact_title`)}</p>
        <div className={classes.field_wrap_long}>
          <EmailInput
            name="email"
            type='email'
            value={data.email}
            validation={validation}
            {...commonProps}
          />
        </div>
      </div>
      <p className={classes.group_title}>{t(`${tKey}.details_title`)}</p>
      <div className={classes.short_fields_container}>
        <div className={classes.field_wrap_short}>
          <InputWrapper
            name="name"
            type='text'
            value={data.name}
            validation={validation}
            {...commonProps}
          />
        </div>
        <div className={classes.field_wrap_short}>
          <InputWrapper
            name="last_name"
            type='text'
            value={data.last_name}
            validation={validation}
            {...commonProps}
          />
        </div>
      </div>
      <div className={classes.field_wrap_long}>
        {(focusedFields.country || data.country.value) && <span className={classes.inside_label}>{t(`${tKey}.form.labels.country`)}</span>}
        <InputWrapper name="country" validation={validation} tKey={tKey}>
          <Select
            className={classNames(classes.field, { [classes.error_border]: !validation.country })}
            iconClassName={classes.arrow}
            name="country"
            options={countries}
            defaultValue={data.country.label}
            loading={!countries}
            {...commonProps}
          />
        </InputWrapper>
        <span className={classNames(classes.placeholder, { [classes.hidden]: focusedFields.country || data.country.value })}>
          {t(`${tKey}.form.placeholders.country`)}
        </span>
      </div>
      {isNeedState &&
        <div className={classes.field_wrap_long}>
          {(focusedFields.state || data.state.value) && <label htmlFor="state" className={classes.inside_label}>{t(`${tKey}.form.labels.state`)}</label>}
          <InputWrapper name="state" validation={validation} tKey={tKey}>
            <Select
              className={classNames(classes.field, { [classes.error_border]: !validation.state })}
              iconClassName={classes.arrow}
              name="state"
              options={states}
              defaultValue={data.state.label}
              loading={!states}
              {...commonProps}
            />
            <span className={classNames(classes.placeholder, { [classes.hidden]: focusedFields.state || data.state.value })}>
              {t(`${tKey}.form.placeholders.state`)}
            </span>
          </InputWrapper>
        </div>
      }
      <div className={classes.field_wrap_long}>
        <InputWrapper
          name="city"
          type='text'
          value={data.city}
          validation={validation}
          {...commonProps}
        />
        <PlaceAutocomplete
          show={focusedFields['city'] && Boolean(data.country.value)}
          value={{ label: data.city.trim() }}
          searchValue={getSearchQuery({ city: debouncedCityValue, country: data.country.value, state: data.state.value })}
          formatter={getFormattedCity}
          onSelect={handleCitySelect}
        />
      </div>
      <div className={classes.field_wrap_long}>
        <InputWrapper
          name="street"
          type='text'
          value={data.street}
          validation={validation}
          {...commonProps}
        />
      </div>
      <div className={classes.field_wrap_long}>
        <InputWrapper
          name="apartment"
          type='text'
          value={data.apartment}
          validation={validation}
          {...commonProps}
        />
      </div>
      <div className={classes.field_wrap_long}>
        <InputWrapper
          name="zip_code"
          type='text'
          value={data.zip_code}
          validation={validation}
          {...commonProps}
        />
      </div>
      <div className={classes.field_wrap_long}>
        {(focusedFields.phone || data.phone) &&
          <label htmlFor="phone"
            className={classes.inside_label}>
            {t(`${tKey}.form.labels.phone`)}
          </label>
        }
        <InputWrapper name="phone" validation={validation} tKey={tKey}>
          <InputPhone
            phoneNumber={data.phone}
            autoCountry={data.phone ? false : true}
            containerClassName={classes.container}
            inputClassName={classNames(classes.input, {
              [classes.error_border]: !validation.phone,
              [classes.gap]: data.phone
            })}
            buttonClassName={classes.flag_button}
            dropdownClassName={classes.dropdown}
            placeholder={t(`${tKey}.form.placeholders.phone`)}
            name='phone'
            setPhoneNumber={(value) => setData({ ...data, phone: value })}
            onValidation={(value) => setValidation({ ...validation, phone: value })}
            {...commonProps}
          />
        </InputWrapper>
      </div>
      <p className={classes.footnote}>{t(`${tKey}.footnote`)}</p>
      <div className={classes.agreementsWrapper}>
        <CheckboxWithLabel
          name='personal'
          text={t(`${tKey}.personal_data_agreement`)}
          isChecked={data.checks.personal}
          onChange={handleCheckChange}
        />
        <CheckboxWithLabel
          name='terms'
          text={
            <T
              k={`${tKey}.terms_agreement`}
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              components={{ serviceTermsLink: <a href={policyLinks.termsOfUse} className={classes.link} /> }}
            />
          }
          isChecked={data.checks.terms}
          onChange={handleCheckChange}
        />
      </div>
      <CompleteButton title={t(`${tKey}.form.button_text`)} disabled={!isFormValid} />
    </form >
  )
}