import { useEffect, useState } from 'react';

import { getBirthChart } from '@wowmaking/birthchart';

import { type PersonalizedData } from 'src/store/profile';

export const useBirthChart = (personalizedData: PersonalizedData | null) => {
  const [data, setData] = useState<{ houses: any, planets: any } | null>(null);

  useEffect(() => {
    const getBirthChartData = async () => {
      const { year, month, day } = personalizedData?.birth_date || {};
      const { lat, lon } = personalizedData?.location || {};
      const { hour, minutes } = personalizedData?.birth_time || {};

      if (year && month && day && lat != null && lon != null) {
        try {
          const res = await getBirthChart({
            birth: {
              date: `${year}-${month}-${day}`,
              time: `${hour || '12'}:${minutes || '00'}`,
              location: { lat: +lat, lon: +lon },
            },
          });

          if (res.success) {
            const { natalPlanets, natalHouses } = res.data;
            setData({ houses: natalHouses, planets: natalPlanets });
          }
        } catch (error) {
          console.error("Error fetching birth chart data:", error);
        }
      }
    };

    getBirthChartData();
  }, [personalizedData]);

  return data;
};
