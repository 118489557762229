import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { getShippingInformation } from 'src/store/profile/selectors';

import { getShippingMethods } from 'src/api/shipping';

import { useRemoteConfig } from 'src/hooks/use-remote-config';

import { Method } from '../components/shipping_method';

type ShippingMethodType = {
  currency: string;
  price: string;
  quote: string;
  service: string;
  shipping_level: string;
  shipping_option: string;
  vat: string;
}


export const useFetchShippingMethods = () => {
  const product = useRemoteConfig().product;

  const formData = useSelector(getShippingInformation);

  const [methodsData, setMethodsData] = useState<Method[] | null>(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    async function getShippingMethodsData() {
      try {
        const shippingMethods = await getShippingMethods({ country: formData.country.value, state: formData.state.value ? formData.state.value : undefined, currency: product?.currency }) as any;
        const adaptedMethodsData = shippingMethods.shipments[0].quotes.map((method: ShippingMethodType) => {
          const price = +method.price;
          const today = dayjs();
          const deliveryIntervalStart = today.add(10, 'day').format('MMMM DD');
          const deliveryIntervalEnd = today.add(14, 'day').format('MMMM DD');

          return {
            id: method.quote,
            deliverier: method.shipping_option,
            shipping_level: method.shipping_level,
            delivery_interval: `${deliveryIntervalStart} - ${deliveryIntervalEnd}`,
            price: price.toFixed(2),
            currency: method.currency
          }
        })
        const sortedMethodsData = adaptedMethodsData.sort((a: Method, b: Method) => parseFloat(a.price) - parseFloat(b.price));
        setMethodsData(sortedMethodsData);
      } catch (error: any) {
        if (error.code === 422) {
          Analytics.trackEvent('shipping_flow_unavailable_country', EVENT_ACTION.ERROR, { country: formData.country.value });
          setIsError(true);
        } else {
          Analytics.trackEvent('shipping_flow_error_delivery', EVENT_ACTION.ERROR, { country: formData.country.value });
          console.error(error.message);
        }
        setIsError(true);
      }
    }
    getShippingMethodsData()

  }, [formData.country.value, formData.state.value, product?.currency]);

  return {
    methodsData,
    isError
  }

};
