import React from "react"

import { t, T } from "@web-solutions/module-localization";
import { Image, Text, Title } from "@web-solutions/core/ui-elements";

import classes from './style.module.scss';
import { IMAGES } from "./images";

const tKey = 'landing.book_info'

export const BookInfo: React.FC = () => {

  return <div id='book' className={classes.wrapper}>
    <div className={classes.content}>
      <Title level='h1' className={classes.title}>
        {t(`${tKey}.title`)}
      </Title>
      <Text className={classes.subtitle}>
        <T k={`${tKey}.subtitle`} />
      </Text>
    </div>
    <Image className={classes.image} alt='book' img={IMAGES.BOOK} />
  </div>
}

export default BookInfo;