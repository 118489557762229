import _map from 'lodash/map';

import Marquee from "react-fast-marquee";

import { t, T } from '@web-solutions/module-localization';

import { ICONS } from './icons';

import classes from './style.module.scss';

const tKey = 'landing.why_choose_us';

type Points = {
  custom: string,
  insights: string,
  analysis: string,
  gift: string,
  design: string,
  quality: string
}

const WhyChooseUs = () => {
  const points = t(`${tKey}.points`, { returnObjects: true }) as any as Points;

  const blocks = _map(points, (v, k) => (
    <div key={k} className={classes.block}>
      <img src={ICONS[k].src} alt="icon" />
      <p className={classes.text}><T k={v} /></p>
    </div>
  ));

  const blocksReverse = [...blocks].reverse();

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        {t(`${tKey}.title`)}
      </div>
      <div className={classes.rings}>
        <Marquee speed={20}>
          <div className={classes.points}>
            {blocks}
          </div>
        </Marquee>
        <Marquee speed={10}>
          <div className={classes.points}>
            {blocksReverse}
          </div>
        </Marquee>
      </div>
    </div>
  );
}

export default WhyChooseUs;