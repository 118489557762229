/* eslint-disable react/no-array-index-key */
import React from 'react';

//@ts-ignore
import * as COLORS from './colors';

import {
  NATAL_WIDTH as WIDTH,
  NATAL_HEIGHT as HEIGHT,
  NATAL_VIEWBOX as VIEWBOX,
  NATAL_R1 as R1,
  NATAL_R2 as R2,
} from './constants';
import {
  checkIfPlanetSm,
  fixObjects,
  getConnections,
  getHousesBars,
  getNatalPlanetCoords,
  getSortedPlanets,
  getWheelBars,
  getWheelDeg,
} from './helpers';
import * as SVG_PATH from './svg-path';
import Planet from './natal-chart-planet';

//@ts-ignore
import classes from './style.module.scss';

export type NatalChartMode = 'book' | 'shop' | 'default';

interface Props {
  houses: any;
  planets: any;
  mode?: NatalChartMode;
}

const NatalChart: React.FC<Props> = ({ houses, planets, mode = 'default' }) => {
  fixObjects(planets);
  fixObjects(houses);

  const housesBars = getHousesBars(houses);
  const wheelDeg = getWheelDeg(houses);
  const wheelBars = getWheelBars(houses);
  const connections = getConnections(planets, houses);
  const sortedPlanets = getSortedPlanets(planets);

  const forBook = mode === 'book';
  const forDefault = mode === 'default';

  return (
    <div className={classes.wheel}>
      <svg viewBox={VIEWBOX}>
        <defs>
          <linearGradient id="planetGrad" x1="1" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={COLORS.SAND_GRADIENT[0]} stopOpacity="1" />
            <>
              <stop offset="18%" stopColor={COLORS.SAND_GRADIENT[1]} stopOpacity="1" />
              <stop offset="51%" stopColor={COLORS.SAND_GRADIENT[2]} stopOpacity="1" />
              <stop offset="100%" stopColor={COLORS.SAND_GRADIENT[3]} stopOpacity="1" />
            </>
          </linearGradient>
          {/* @ts-ignore */}
          {planets.map(planet => (
            <symbol id={planet?.name} viewBox="0 0 16 16" key={planet?.name}>
              <path
                //@ts-ignore
                d={SVG_PATH.PLANETS[planet?.name]}
                fill={!forDefault ? COLORS.BLACK : COLORS.DARK_BLUE_GREY_3}
              />
            </symbol>
          ))}
        </defs>
        {/* @ts-ignore */}
        {housesBars.map(bar => (
          <g key={bar.title}>
            <line {...bar.coords} stroke={COLORS.BEIGE_2} strokeWidth={forBook ? 0.84 : 0.5} opacity={forBook ? 1 : 0.5} />
            <text
              style={{
                transform: `translate(${WIDTH / 2}px, ${WIDTH / 2}px) rotate(${bar.titleRotation}deg) translate(-${WIDTH / 2}px, -${WIDTH / 2}px)`,

              }}
              x={WIDTH / 2}
              y={30}
              textAnchor="start"
              fill={COLORS.BEIGE_2}
              fontSize={8}
              opacity={forBook ? 1 : 0.35}
              //@ts-ignore
              rotation={bar.titleRotation}
            >
              {bar.title}
            </text>
          </g>
        ))}

        {wheelBars.map((bar, i) => (
          <line {...bar.coords} stroke={COLORS.BEIGE_2} strokeWidth={forBook ? 0.84 : 1.5} opacity={forBook ? 1 : 0.4} key={i} />
        ))}

        {[R1, R2].map(r => (
          <circle
            cx={WIDTH / 2}
            cy={HEIGHT / 2}
            r={r}
            stroke={COLORS.BEIGE_2}
            strokeWidth={forBook ? 0.84 : 1.5}
            opacity={forBook ? 1 : 0.4}
            strokeOpacity={forBook ? 0.84 : 0.4}
            fill={!forDefault ? 'rgba(4, 6, 10, 0)' : ''}
            key={r}
          />
        ))}

        <path
          d={SVG_PATH.SIGNS}
          fill={COLORS.TOUPE}
          opacity={forBook ? 1 : 0.5}
          style={{ transform: `translate(${WIDTH / 2}px, ${WIDTH / 2}px) rotate(${wheelDeg}deg) translate(-${WIDTH / 2}px, -${WIDTH / 2}px)` }}
        />

        {connections.map((connection, i) => (
          <line
            {...connection.coords}
            stroke={connection.type === 'tense' ? COLORS.FADED_ORANGE : COLORS.TEAL}
            strokeWidth={1.5}
            opacity={0.6}
            key={i}
          />
        ))}

        {sortedPlanets.map(planet => (
          <Planet
            coords={getNatalPlanetCoords(planet, houses)}
            name={planet.name}
            sm={checkIfPlanetSm(planet, planets)}
            key={planet.name}
            circleColor={!forDefault ? COLORS.BLACK : COLORS.DARK_BLUE_GREY}
            strokeWidth={forBook ? 0.27 : null}
          />
        ))}
      </svg>
    </div>
  );
}

/* Export */
export default NatalChart
