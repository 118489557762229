export const BLACK = '#000000'; // TODO: check if everything is usable
export const BLACK_07 = 'rgba(0, 0, 0, 0.7)';
export const BLACK_OPACITY = BLACK + '50';
export const ALTO = '#d8d8d8';
export const GREY = '#999';
export const UNACTIVE_ROW = 'rgba(208, 182, 255, 0.50)';
export const NIGHT_BLUE_TRANSPARENT = 'rgba(24, 4, 60, 0.5)';
export const SHADOW = 'rgba(0, 0, 0, 0.2)';
export const ROZE = '#fd6a61';
export const RED = '#ff3738';
export const LIGHT_GREY = '#b6b8be';
export const WARM_GREY = '#949494';
export const GRAY_DARK = '#818080';
export const BLUEY_GREY = '#9697b9';
export const PASTEL_PURPLE = '#cea3ff';
export const BLUE_PURPLE = '#652fcb';
export const BLUISH_PURPLE_TWO = '#7c34ec';
export const PERRYWINKLE = '#8680df';
export const PERRYWINKLE_GRAY = '#c4c2e5';
export const VERY_LIGHT_PURPLE = '#e2d1fb';
export const MELROSE = '#b0aeff';
export const ELECTRIC_PURPLE = '#9043ff';
export const LIGHTER_PURPLE = '#884ef8';
export const EASTER_PURPLE_TWO = '#a466ff';
export const LIGHTER_PURPLE_SIX = '#915cf6';
export const DISABLE_BUTTON = '#6f6499';
export const SELECTION_COMPLETED = '#e44bd3';
export const PALM_NOTIFICATION = '#F037BA';
export const PURPLE_PINK = '#e42cd0';
export const SALMON = '#ff7e76';
export const SELECTION_UNCOMPLETED = '#dac2ff';
export const LILIAC = '#be91ff';
export const PASTEL_PURPLE_TWO = '#d1b2ff';
export const BLUE_VIOLET = '#554eba';
export const PALE_LAVENDER = '#f2ebff';
export const PALE_LILAC_TWO = '#f0e7ff';
export const PALE_LILAC_THREE = '#ece1ff';
export const PALE_LILAC_FIVE = '#e5d4ff';
export const PALE_PURPLE = '#ac8ddd';
export const PALE_PURPLE_ALPHA = '#9b9ad5';
export const PALM_BG = '#78AAB199';
export const UNDER_BUTTON_TEXT = '#dfc8ff';
export const PALE_LILAC = '#dfc8ff';
export const PERIWINLE = '#a170ff';
export const BLURPLE = '#6331c0';
export const BLUEBERRY = '#5550a9';
export const BLUEBERRY_TWO = '#322e76';
export const DARKISH_PURPLE = '#3d1c72';
export const DARKISH_PURPLE_TRANSPARENT = 'rgba(61, 28, 114, 0.6)';
export const GRAPE_PURPLE = '#33185f';
export const GRAPE_PURPLE_TRANSPARENT = 'rgba(51, 24, 95, 0.9)';
export const SNUFF = '#dddaf1';
export const PALE_VIOLET = '#c7a5f9';
export const LUCKY_POINT = '#241459';
export const PALE_VIOLET_TWO = '#d5bcfa';
export const AQUA_MARINE = '#46e8e1';
export const CARNATION_PINK = '#ff79a6';
export const BRIGHT_TURQUOISE = '#0df8dd';
export const WHEAT = '#fcc678';
export const VIOLET = '#1e0943';
export const WARM_PINK = '#fd5b88';
export const GRAY_DARK_TRANSPERENT = 'rgba(0, 0, 0, 0.50)';
export const PURPLE_GREY = '#CCC1DE';
export const EMOTIONAL_COLOR = BRIGHT_TURQUOISE;
export const PHYSICAL_COLOR = '#f2f660';
export const INTELLECTUAL_COLOR = '#ff22f9';
export const DARK_BLUE = '#1e0843';
export const BG = DARK_BLUE;
export const BG_SEMI_TRANSPARENT = BG + 'cc';
export const CHART_GRID_COLOR = PALE_VIOLET;
export const EMPTY_PLACE_FOR_TAROT = BLURPLE;
export const CHART_BAR_LINE = 'rgba(134, 65, 244, 0.8)';
export const DARK_PURPLE = '#6c32d8';
export const PINK = '#ff89e0';
export const VIOLET_PINK = '#ff3ffa';
export const BLUEY_PURPLE = '#6431b5';
export const LIGHTER_PURPLE_FIVE = '#894ff7';
export const PURPLEY = '#8749e8';
export const AQUAMARINE = '#00cdac';
export const ROBIN_EGG_BLUE = '#82fffa';
export const TEALISH = '#42d1d2';
export const BRIGHT_SKY_BLUE = '#0fccff';
export const BRIGHT_LIGHT_BLUE = '#36d1f3';
export const BRIGHT_AQUA = '#00ffe1';
export const BRIGHT_AQUA_TWO = '#0df8dd';
export const PIG_PINK = '#e86d9e';
export const ROWDER_PINK = '#ffa1e5';
export const SCARLET_GUM = '#2d1455';
export const BLACK_RUSSIAN = '#070026';
export const SCOOTER = '#2cb3d1';
export const MEDIUM_PURPLE = '#9659ee';
export const BRIGHT_TEAL = '#00eedb';
export const DARK_TEAL = '#1E3B48';
export const LIGTH_CYAN = '#abffff';
export const CERULEAN = '#0082df';
export const ANAKIWA = '#abedff';
export const DARK_SLATE_BLUE = '#2b2472';
export const BLUE_WITH_A_HINT_OF_PURPLE = '#3935ac';
export const STRONG_PINK = '#ff229d';
export const PASTEL_BLUE = '#b0aeff';
export const LIGHT_MAGENTA = '#f689fa';
export const BUBBLE_GUM_PINK = '#ff5e96';
export const BATTLESHIP_GRAY = '#6a7082';
export const INDIGO = '#5C54CD';
export const HONEYSUCKLE = '#F2F660';
export const SETTINGS_BUTTON_SHADOW = 'rgba(24, 4, 60, 0.5)';
export const SETTINGS_ACTIVE_BUTTON_SHADOW = 'rgba(101, 255, 255, 0.5)';
export const RADICAL_RED = '#ff3970';
export const INACTIVE_TAB = '#3d7481';
export const ACTIVE_TAB = '#31bddf';
export const INACTIVE_TEXT = '#706BBC';
export const ONBOARDING_LOADER_BG = 'rgba(255, 255, 255, 0.2)';
export const TASK_HIGHLIGHT = '#5B53FF';
export const MANUAL_MODAL_BG = 'rgba(30, 9, 67, 0.7)';
export const TABS_BORDER = '#0188DF';
export const WHITE_WITH_FIFTH_TRANSPARENCY = 'rgba(255, 255, 255, 0.8)';
export const CHEVRON = '#06ffdc';
export const PROFILE_PLACEHOLDER = 'rgba(255, 255, 255, 0.5)';
export const PROFILE_UNDERLINE = 'rgba(155, 154, 213, 0.4)';
export const LIGHT_PURPLE = '#a8a7ed';
export const LIGHT_GREY_OPACITY = 'rgba(118, 118, 128, 0.3)';
export const DIVIDER_LIGHT_GREY = '#244a5b';
export const INACTIVE_ITEM = '#d3d2ff';
export const PICKER_LINE = 'rgba(211, 210, 255, 0.2)';
export const ADDRESS_UNDERLINE = '#58578c';
export const LILAC_AMETHYST = '#5946be';
export const FAUX_MEDIUM_BLUE = '#716ae3';
export const SLIGHTLY_DESATURATED_BLUE = '#9f9ed8';
export const LIGHT_STEEL_BLUE = '#b9b8e2';
export const BLUE_MAGENTA = '#7b77c8';
export const DOMINATE_BLUE = '#3531a7';
export const STRONG_CYAN = '#049fc6';
export const USER_MESSAGE = '#dddbff';
export const VERY_LIGHT_BLUE = '#9682ff';
export const DARK_BLUE_MAGENTA = '#4e479d';
export const CATALINA_BLUE = '#7d71ce';
export const GHOST_WHITE = '#f5f4ff';
export const LIGHT_LAVENDER = '#e4e3ff';
export const LAVENDER = '#e4e1f9';
export const MOODY_BLUE = '#7d71ce';
export const DANDELION = '#ffdd69';
export const RAJAH = '#fbb566';
export const MATISSE = '#3d5875';
export const BRILLIANT_ROSE = '#f03a9f';
export const BAY_OF_MAY = '#29257d';
export const GOVERNOR_BAY = '#3a34aa';
export const VIOLET_RED = '#f8347a';
export const VIOLET_RED_OPACITY = 'rgba(102, 17, 58, 0.3)';
export const SPRING_GREEN = '#00ff94';
export const MEDIUM_SPRING_GREEN = '#00f68f';
export const CARIBBEAN_GREEN = '#00de81';
export const MEDIUM_SPRING_GREEN_OPACITY = 'rgba(25, 78, 43, 0.3)';
export const QUARTZ = '#d4d3e5';
export const PURPLE_HEART = '#502DB6';
export const BRIGHT_TURQUOISE_2 = '#1FE7E8';
export const LIGHT_SLATE_BLUE = '#8C85FF';
export const CERISE = '#31bddf';
export const SHAMROCK = '#31c9af';
export const METEORITE = '#2e1d7a';
export const DARK_LUCKY_POINT = '#2b1a6d';
export const COBALT = '#2b1a6e';
export const LIGHT_BLUE_GREY = '#bab8e1';
export const PORTAGE = '#9994f4';
export const GOLDEN = '#F2E6C4';
export const GOLDENROD = '#ffdd69';
export const JACKSONS_PURPLE = '#211f86';
export const SELAGO = '#f7f6fd';
export const PALE_LILAC_2 = '#f4f3ff';
export const DARK_SLATE_BLUE_2 = '#2c2575';
export const DEEP_CERULEAN = '#0088a6';
export const MEDIUM_RED_VIOLET = '#c42aa9';
export const FROLY = '#ee6a88';
export const IRIS = '#5946be';
export const BLUEBERRY_THREE = '#343098';
export const HELIOTROPE = '#f25aff';
export const PERIWINKLE = '#9d97ff';
export const LIGHT_BLUE_GREY_3 = 'rgba(33, 95, 121, 0.3)';

export const CARD_BG_COLOR = '#1E3B48';
export const MODAL_BG_COLOR = '#1E3B48';

export const GRADIENT_CARD_TRANSPARENT = [CARD_BG_COLOR + '00', CARD_BG_COLOR];
export const GRADIENT_COLORS_BUTTON = ['#fd5b86', '#eb48d5'];
export const GRADIENT_PRIMARY_BLUE_BUTTON = [BRIGHT_TEAL, CERULEAN];
export const GRADIENT_SETTINGS_BUTTON = [BRIGHT_SKY_BLUE, BRIGHT_AQUA];
export const GRADIENT_COMPATIBILITY_ACTIVE = [BLUEBERRY_TWO, BLUEBERRY_TWO];
export const GRADIENT_COLORS_DISABLE_BUTTON = [BLUEBERRY, BLUEBERRY];
export const GRADIENT_COLORS_DISABLE_SELECT = ['#9D8FB7', '#A192BA'];
export const GRADIENT_COLORS_UNSELECT = ['#884cfa', '#9f67f7'];
export const GRADIENT_COLORS_MY_MSG = ['#9e66f6', '#ea5df8'];
export const GRADIENT_COLORS_BUTTON_ONB_SEND = ['#0fccff', '#00ffe1'];
export const GRADIENT_COLORS_PICKERS = ['#b283f5', '#ec2df8'];
export const GRADIENT_COLORS_ACTIVITY_LEVEL = [RADICAL_RED, STRONG_PINK];
export const GRADIENT_CAMERA_SHOT = ['#7a42e4', '#9f67f7'];
export const GRADIENT_BG_MAIN_SCREEN = ['#6a34d2', '#8b4cee'];
export const GRADIENT_CARDS = [BLUE_WITH_A_HINT_OF_PURPLE, DARK_SLATE_BLUE];
export const GRADIENT_CARDS_REVERT = [DARK_SLATE_BLUE, BLUE_WITH_A_HINT_OF_PURPLE];
export const LILAC_PURPLE_GRADIENT = [LILAC_AMETHYST, BLUE_WITH_A_HINT_OF_PURPLE];
export const GRADIENT_EXTRA = ['rgba(136, 76, 250, 0.7)', 'rgba(159, 103, 247, 0.7)'];
export const GRADIENT_LILAC_DOMINATE = [LILAC_AMETHYST, DOMINATE_BLUE];
export const GRADIENT_VIOLET_FROLY = [MEDIUM_RED_VIOLET, FROLY];
export const GRADIENT_SALMON = [SALMON, SALMON];
export const GRADIENT_HONEYSUCKLE = [HONEYSUCKLE, HONEYSUCKLE];
export const GRADIENT_HELIOTROPE = [HELIOTROPE, HELIOTROPE];
export const GRADIENT_SPRING_GREEN = [SPRING_GREEN, SPRING_GREEN];
export const GOLDENROD_RAJAH = [GOLDENROD, RAJAH];
export const GRADIENT_CHART_LATERAL = ['#2d2679', 'rgba(44, 37, 119, 0.48)', 'rgba(57, 51, 139, 0)'];

export const ADD_FRIENDS_GRADIENT = ['rgba(6, 255, 220, 0.8)', 'rgba(80, 182, 255, 0.8)'];
export const PREMIUM_BANNER_GRADIENT_PINK = ['#f689fa', '#ff5e96'];
export const PREMIUM_BANNER_GRADIENT_GREEN = ['#06ffdc', '#50b6ff'];
export const RETRY_GRADIENT = ['#00eedb', '#0082df'];
export const ALL_UPDATES_GRADIENT = ['#00eedb', '#0082df', '#0082df'];
export const BOTTOM_GRADIENT = ['rgba(0,0,0,0)', DARK_BLUE];
export const WHITE_WITH_OPACITY = 'rgba(255, 255, 255, 0.6)';
export const WHITE_19 = 'rgba(255, 255, 255, 0.19)';
export const WHITE_HALF = 'rgba(255, 255, 255, 0.5)';
export const DARK_PURPLE_GRADIENT = ['#4f4b86', '#4f4b86'];
export const BLUE_WITH_PURPLE_HINT = ['#311985', '#372ea3', 'rgba(57, 53, 172, 0)'];
export const LILAC_WITH_BLUE_GRADIENT = [LILAC_AMETHYST, DOMINATE_BLUE];
export const DARK_LILAC_WITH_BLUE_GRADIENT = [METEORITE, DARK_LUCKY_POINT];
export const DARK_BLUE_GRADIENT = ['#1e1b6c', '#2a227d'];
export const CATALINA_BLUE_GRADIENT = [CATALINA_BLUE, CATALINA_BLUE];
export const DANDELION_RAJAH_GRADIENT = [DANDELION, RAJAH];
export const LILAC_WITH_PURPLE_BETA = [LILAC_AMETHYST, BLUE_WITH_A_HINT_OF_PURPLE];
export const BLUR_BLUE = 'rgba(32, 20, 94, 0.9)';
export const BLUR_VIOLET = 'rgba(24, 4, 60, 0.19)';
export const BLUR_VIOLET_HALF = 'rgba(24, 4, 60, 0.5)';
export const BLUE_TRANSPARENT = ['rgba(33, 11, 76, 0)', 'rgba(35, 12, 79, 0.7)', '#240d51'];
export const BLACK_TRANSPARENT = 'rgba(0, 0, 0, 0.3)';
export const BLUE_TRANSPARENT_2 = ['rgba(41, 76, 91, 0)', '#294C5B'];

/* Colors for AstroStellium theme */

export const TRANSPARENT = 'transparent';
export const WHITE = '#ffffff';
export const WHITE_WITH_OPACITY_0_15 = 'rgba(255, 255, 255, 0.15)';
export const BEIGE = '#faf6e8';
export const BEIGE_2 = '#F0CA7D';
export const BEIGE_3 = '#e2cfa4';
export const BEIGE_WITH_OPACITY_01 = 'rgba(242, 230, 196, 0.2)';
export const BEIGE_WITH_OPACITY_03 = 'rgba(226, 207, 164, 0.3)';
export const BEIGE_WITH_OPACITY_05 = 'rgba(242, 230, 196, 0.5)';
export const TOUPE = '#C7A053';
export const TOUPE_08 = 'rgba(208, 184, 148, 0.8)';
export const TOUPE_2 = '#888c80';
export const SAND_BROWN = '#d3935e';
export const PALE_BROWN = '#bb9576';
export const STONE = '#a39376';
export const LIGHT_TAN = '#fae6c4';
export const LIGHT_GRAY = '#e7e7e7';
export const CORAL = '#fd5347';
export const FADED_ORANGE = '#f27067';
export const AQUA = '#14d5c2';
export const AQUA_LIGHT = '#11bcaa';
export const TOPAZ = '#19cebb';
export const TOPAZ_02 = 'rgba(25, 206, 187, 0.2)';
export const DENIM = '#3d7481';
export const DENIM_DARK = '#196e6c';
export const DIRTY_BLUE = '#43909e';
export const DIRTY_BLUE_OPACITY = 'rgba(67, 144, 158, 0.1)';
export const DUSK_BLUE = '#247583';
export const DARK_GREY_BLUE = '#2d5668';
export const LIGHT_GREY_BLUE = '#336479';
export const LIGHT_GREY_BLUE_08 = 'rgba(45, 86, 104, 0.8)';
export const DARK_BLUE_GREY = '#152b35';
export const DARK_BLUE_GREY_2 = '#101D23';
export const DARK_BLUE_GREY_2_09 = 'rgba(16, 29, 35, 0.9)';
export const DARK_BLUE_GREY_3 = '#344548';
export const TURQUOISE_BLUE = '#0b9eae';
export const SILVER = '#d5dde1';
export const LIGHT_MUSTARD = '#f9d05d';
export const LIGHT_MUSTARD_02 = 'rgba(249, 208, 93, 0.2)';
export const LILAC = '#c583fa';
export const LILAC_02 = 'rgba(197, 131, 250, 0.2)';
export const RHINO = '#2c495b';
export const TEAL = '#27baa7';
export const TEAL2 = '#00EEDB';
export const TEAL3 = '#19CEBB';
export const DARK_BLUE_GREY_2_TRANSPARENT = 'rgba(30, 59, 72, 0.7)';
export const DARK_BLUE_GREY_2_TRANSPARENT_09 = 'rgba(30, 59, 72, 0.9)';
export const DARK_BLUE_GREY_4 = '#274B5B';
export const DARK_BLUE_GREY_5 = '#1E3B47';
export const DARK_BLUE_GREY_6 = '#427389';
export const DARK_BLUE_GREY_7 = '#3A7087';
export const DARK_BLUE_GREY_8 = '#172D36';
export const DARK_BLUE_GREY_9 = '#152B35';

export const GRADIENT_COLORS_BUTTON_ALT = [TURQUOISE_BLUE, AQUAMARINE];
export const GRADIENT_WHITE = [WHITE, WHITE];
export const GRADIENT_WHITE_TRANSPERENT = ['rgba(255, 255, 255, 0.3)', 'rgba(255, 255, 255, 0.5)', WHITE];
export const TEAL_GRADIENT = ['#00eedb', '#27baa7'];
export const TEAL_GRADIENT_DARK = ['#008077', '#1a766a'];
export const TURQUOISE_GRADIENT = ['#0b9eae', '#0baea5'];
export const SAND_GRADIENT = ['#e5a87f', '#ecbf98', '#f7e6c4', '#e5a87f'];
export const APRICOT_GRADIENT = ['#ffdd69', '#fbb566'];
export const TRANSPARENT_GRADIENT = ['transparent', 'transparent'];
export const DARK_GREY_BLUE_TRANSPARENT_GRADIENT = [DARK_GREY_BLUE + '00', DARK_GREY_BLUE];
export const DARK_TEAL_TRANSPARENT_GRADIENT_2 = [DARK_TEAL + '00', DARK_TEAL];
export const DARK_GREY_BLUE_60_GRADIENT = [DARK_BLUE_GREY + '66', DARK_BLUE_GREY + '66'];
export const GRADIENT_DISABLE_BUTTON_SETTINGS_LANGUAGE = ['#152b35', '#152b35'];
export const GRADIENT_DARK_GREY_ONE_COLOR = [DARK_BLUE_GREY_4, DARK_BLUE_GREY_4];
export const GRADIENT_DARK_GREY = ['#3B7D99', '#316175'];
export const SURVEY_MODAL_BACKGROUND = 'rgba(16, 29, 35, 0.8)';
export const LIGHT_GREY_BLUE_2 = '#1e586a';
export const LIGHT_BEIGE = 'rgba(250, 246, 232, 0.8)';
export const SEMI_BEIGE = 'rgba(250, 246, 232, 0.5)';
export const SEMI_LIGHT_BEIGE = 'rgba(250, 246, 232, 0.2)';
export const LIGHT_POWDER_BLUE = 'rgba(20, 213, 194, 0.1)';
export const SEMI_LIGHT_POWDER_BLUE = 'rgba(18, 62, 69, 0.5)';
export const REPORT_PROGRESS_BG = '#35637755';
export const OVERLAY_BACKGROUND = 'rgba(21, 43, 53, 0.97)';
export const LIGHT_BLUE_GREY_08 = 'rgba(21, 43, 53, 0.8)';
export const DARK_GREEN = '#101D23';
export const WHITE_005 = 'rgba(255, 255, 255, 0.05)';
export const DARK_LAVENDER = '#2C3651';
export const ORANGE = '#FF994F';
export const BLUE = '#31B4F2';
export const BLUE_02 = 'rgba(49, 180, 242, 0.2)';
export const BLUE_08 = 'rgba(23, 45, 54, 0.8)';
export const BIRTH_CHART_BG = '#11232B';
export const PRIMARY_COLOR = '#14D4C1';
export const SECONDARY_COLOR = '#F9F5E7';
export const COMPATIBILITY_BUTTON_GRADIENT = ['rgba(16, 29, 35, 0)', 'rgba(16, 29, 35, 0.65)', 'rgba(16, 29, 35, 0.9)'];