
import { OrderButton } from 'src/components/order/order-button';

import classes from './style.module.scss';

interface Props {
  title: string;
  disabled?: boolean;
  onCompleteButtonClick?: () => void;
}

export const CompleteButton: React.FC<Props> = ({ title, disabled, onCompleteButtonClick }) => (
  <OrderButton
    type='submit'
    className={classes.button}
    disabled={disabled}
    title={title}
    onClick={onCompleteButtonClick}
  />
)