import { useEffect, useState, useMemo } from 'react';

import { getBirthChart } from '@wowmaking/birthchart';

import { type PersonalizedData } from 'src/store/profile';

const housesAndPlanetsCache = new Map();

export const useHousesAndPlanetsData = (personalizedData: PersonalizedData | null) => {
  const { birth_date, location, birth_time } = personalizedData || {};

  const { year, month, day } = birth_date || {};
  const { lat, lon } = location || {};
  const { hour, minutes } = birth_time || {};

  const birthChartParams = useMemo(() => year && month && day && lat != null && lon != null ?
    ({
      date: `${year}-${month}-${day}`,
      time: `${hour || '12'}:${minutes || '00'}`,
      location: { lat: +lat, lon: +lon },
    }) :
    null,
    [year, month, day, hour, minutes, lat, lon])

  const [data, setData] = useState<{ house: number, sign: string, name: string }[] | null>(() => housesAndPlanetsCache.get(JSON.stringify(birthChartParams) ?? null));

  useEffect(() => {
    const getBirthChartData = async () => {

      if (!birth_date || !location) {
        setData(null);
        return;
      }

      if (birthChartParams) {
        try {
          const birthChartParamsString = JSON.stringify(birthChartParams)

          const cachedhousesAndPlanets = housesAndPlanetsCache.get(birthChartParamsString)

          if (cachedhousesAndPlanets) {
            setData(cachedhousesAndPlanets);
            return
          }

          const res = await getBirthChart({
            birth: birthChartParams,
          });

          if (res.success) {
            const { planetsWithSignsAndHouses } = res.data;

            if (planetsWithSignsAndHouses) {
              housesAndPlanetsCache.set(birthChartParamsString, planetsWithSignsAndHouses)
            }

            setData(planetsWithSignsAndHouses);
          }
        } catch (error) {
          console.error("Error fetching birth chart data:", error);
        }
      }
    };

    getBirthChartData();
  }, [birth_date, location, birth_time, birthChartParams]);

  return data;
};
