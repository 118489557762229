import { ImagesMap } from 'core/interfaces/images';

import PIC1_1X from './pic1.webp'
import PIC1_2X from './pic1@2x.webp'
import PIC1_3X from './pic1@3x.webp'

import PIC2_1X from './pic2.webp'
import PIC2_2X from './pic2@2x.webp'
import PIC2_3X from './pic2@3x.webp'

import PIC3_1X from './pic3.webp'
import PIC3_2X from './pic3@2x.webp'
import PIC3_3X from './pic3@3x.webp'

import PIC4_1X from './pic4.webp'
import PIC4_2X from './pic4@2x.webp'
import PIC4_3X from './pic4@3x.webp'

import PIC5_1X from './pic5.webp'
import PIC5_2X from './pic5@2x.webp'
import PIC5_3X from './pic5@3x.webp'

import PIC6_1X from './pic6.webp'
import PIC6_2X from './pic6@2x.webp'
import PIC6_3X from './pic6@3x.webp'

import PIC7_1X from './pic7.webp'
import PIC7_2X from './pic7@2x.webp'
import PIC7_3X from './pic7@3x.webp'

import PIC8_1X from './pic8.webp'
import PIC8_2X from './pic8@2x.webp'
import PIC8_3X from './pic8@3x.webp'

import PIC9_1X from './pic9.webp'
import PIC9_2X from './pic9@2x.webp'
import PIC9_3X from './pic9@3x.webp'

import PIC10_1X from './pic10.webp'
import PIC10_2X from './pic10@2x.webp'
import PIC10_3X from './pic10@3x.webp'


export const IMAGES: ImagesMap = {
  PIC1: {
    src: PIC1_1X,
    srcSet:`${PIC1_1X}, ${PIC1_2X} 2x, ${PIC1_3X} 3x`
  },
  PIC2: {
    src: PIC2_1X,
    srcSet:`${PIC2_1X}, ${PIC2_2X} 2x, ${PIC2_3X} 3x`
  },
  PIC3: {
    src: PIC3_1X,
    srcSet:`${PIC3_1X}, ${PIC3_2X} 2x, ${PIC3_3X} 3x`
  },
  PIC4: {
    src: PIC4_1X,
    srcSet:`${PIC4_1X}, ${PIC4_2X} 2x, ${PIC4_3X} 3x`
  },
  PIC5: {
    src: PIC5_1X,
    srcSet:`${PIC5_1X}, ${PIC5_2X} 2x, ${PIC5_3X} 3x`
  },
  PIC6: {
    src: PIC6_1X,
    srcSet:`${PIC6_1X}, ${PIC6_2X} 2x, ${PIC6_3X} 3x`
  },
  PIC7: {
    src: PIC7_1X,
    srcSet:`${PIC7_1X}, ${PIC7_2X} 2x, ${PIC7_3X} 3x`
  },
  PIC8: {
    src: PIC8_1X,
    srcSet:`${PIC8_1X}, ${PIC8_2X} 2x, ${PIC8_3X} 3x`
  },
  PIC9: {
    src: PIC9_1X,
    srcSet:`${PIC9_1X}, ${PIC9_2X} 2x, ${PIC9_3X} 3x`
  },
  PIC10: {
    src: PIC10_1X,
    srcSet:`${PIC10_1X}, ${PIC10_2X} 2x, ${PIC10_3X} 3x`
  },
}