import React, { useCallback, type BaseSyntheticEvent } from 'react';
import classNames from 'classnames';

import { Input } from 'core/ui-elements';

import { PlaceAutocomplete } from './components';

import type { PlaceData } from './types';

import classes from './style.module.scss';

interface Props {
  placeholder: string;
  value: any;
  onChange: (value: any) => void;
  onFocus?: (e: BaseSyntheticEvent) => void;
  onBlur?: (e: BaseSyntheticEvent) => void;
  onSelect?: (e: BaseSyntheticEvent) => void;
  isFocused?: boolean;
  className?: string;
  inputClass?: string;
  autoFocus?: boolean;
}

export const PlacePicker: React.FC<Props> = ({
  value,
  onChange,
  onFocus,
  onBlur,
  onSelect,
  placeholder,
  className,
  inputClass,
  autoFocus,
  isFocused,
}) => {
  const handleInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ label: e.target.value });
  }, [onChange]);

  const handleSelect = useCallback((o: PlaceData) => {
    onChange(o);
    onSelect && onSelect({ target: { name: 'location', value: o } } as BaseSyntheticEvent );
  }, [onChange, onSelect]);

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      onBlur && onBlur({ target: { name: 'location' } } as BaseSyntheticEvent);
    }
  }

  return (
    <div onBlur={handleBlur} className={classNames(classes.wrapper, className)}>
      <Input
        autoFocus={autoFocus}
        className={classNames(classes.input, inputClass)}
        value={value?.label || ''}
        onChange={handleInput}
        onFocus={onFocus}
        placeholder={placeholder}
        name='location'
      />
      <PlaceAutocomplete
        value={value}
        onSelect={handleSelect}
        show={isFocused}
      />
    </div>
  );
}
