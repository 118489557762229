import { ImagesMap } from 'core/interfaces/images';

import FINGERPRINT from './fingerprint.svg';
import TROPHY from './trophy.svg';
import ANALYSIS from './analysis.svg';
import GIFT from './gift.svg';
import HEART from './heart.svg';
import FLOWER from './flower.svg';


export const ICONS: ImagesMap = {
  custom: {
    src: FINGERPRINT,
  },
  insights: {
    src: TROPHY,
  },
  analysis: {
    src: ANALYSIS,
  },
  gift: {
    src: GIFT,
  },
  design: {
    src: HEART,
  },
  quality: {
    src: FLOWER,
  }
}