import type { Currency } from '@web-solutions/react-billing/constants';

import request from '@web-solutions/core/utils/network';

const COUNTRIES = '/payments/shipping/countries';
const STATES = '/payments/shipping/states';
const SHIPPING_PRICES = '/payments/shipping/shipping-prices';

export async function getCountries() {
  return request.get(COUNTRIES);
}

export async function getStates(country: string) {
  return request.get(`${STATES}?country=${country}`);
}

export async function getShippingMethods({ currency, ...params }: { country: string, state?: string, currency?: Currency }) {
  return request.get(SHIPPING_PRICES, {
    params: {
      currency: currency ?? 'USD',
      ...params
    }
  });

} 