import BOOK_METRIC_1X from './book_metric@1x.webp';
import BOOK_METRIC_2X from './book_metric@2x.webp';
import BOOK_METRIC_3X from './book_metric@3x.webp';

import BOOK_IMPERIAL_1X from './book_imperial@1x.webp';
import BOOK_IMPERIAL_2X from './book_imperial@2x.webp';
import BOOK_IMPERIAL_3X from './book_imperial@3x.webp';

export const IMAGES = {
  BOOK_METRIC: {
    src: BOOK_METRIC_1X,
    srcSet: `${BOOK_METRIC_1X} 1x, ${BOOK_METRIC_2X} 2x, ${BOOK_METRIC_3X} 3x`,
  },
  BOOK_IMPERIAL: {
    src: BOOK_IMPERIAL_1X,
    srcSet: `${BOOK_IMPERIAL_1X} 1x, ${BOOK_IMPERIAL_2X} 2x, ${BOOK_IMPERIAL_3X} 3x`,
  }
}