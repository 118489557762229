import { SummarySection } from 'src/types/remote-config';

import BirthChart from './birth-chart';
import BookInfo from './book-info';
import BookSpecifications from './book-specifications';
import ContactUs from './contact-us';
import DeliverySteps from './delivery-steps';
import Faq from './faq';
import GreatInsights from './great-insights';
import ShareJoy from './share-joy';
import Surprise from './surprise';
import Welcome from './welcome';
import Header from './header';
import WhatInside from './what-inside';
import WhyChooseUs from './why-choose-us';
import PlanetsAffect from './planets-affect';
import CosmicGuidance from './cosmic-guidance';
import BookSwiper from './book-swiper';
import ReadersSay from './readers-say';


export const SECTIONS: {
  [key in SummarySection]: JSX.Element
} = {
  [SummarySection.BIRTH_CHART]: <BirthChart />,
  [SummarySection.BOOK_INFO]: <BookInfo />,
  [SummarySection.BOOK_SPECIFICATIONS]: <BookSpecifications />,
  [SummarySection.CONTACT_US]: <ContactUs />,
  [SummarySection.DELIVERY_STEPS]: <DeliverySteps />,
  [SummarySection.FAQ]: <Faq />,
  [SummarySection.GREAT_INSIGHTS]: <GreatInsights />,
  [SummarySection.SHARE_JOY]: <ShareJoy />,
  [SummarySection.SURPRISE]: <Surprise />,
  [SummarySection.WELCOME]: <Welcome />,
  [SummarySection.WHAT_INSIDE]: <WhatInside />,
  [SummarySection.HEADER]: <Header />,
  // personalization
  [SummarySection.WHY_CHOOSE_US]: <WhyChooseUs />,
  [SummarySection.PLANETS_AFFECT]: <PlanetsAffect />,
  [SummarySection.COSMIC_GUIDANCE]: <CosmicGuidance />,
  [SummarySection.BOOK_SWIPER]: <BookSwiper />,
  [SummarySection.READERS_SAY]: <ReadersSay />,
};
